define("cco-seek/utils/rows-to-cols", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = rowsToCols;

  function rowsToCols(array, numCols) {
    if (!Array.isArray(array)) {
      throw 'First argument must be an array.';
    } else if (!isFinite(numCols)) {
      throw 'Second argument must be a number specifying number of columns.';
    } else if (!array.length) {
      return array;
    } else {
      var cols = [];
      var numRows = Math.ceil(array.length / numCols); // Split the array into rows:

      var col = 0;

      while (array.length > 0) {
        cols[col++] = array.splice(0, numRows);
      } // Zip the rows back into a single array:


      var zipped = [];
      cols[0].forEach(function (_item, index) {
        var arr = cols.map(function (col) {
          return col[index];
        });
        arr = arr.filter(function (item) {
          return item !== undefined;
        });
        zipped = zipped.concat(arr);
      });
      return zipped;
    }
  }
});