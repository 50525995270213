define("cco-seek/transitions/slide-out-left", ["exports", "cco-seek/transitions/slide-over"], function (_exports, _slideOver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = slideOutLeft;

  function slideOutLeft(opts) {
    return _slideOver.default.call(this, 'x', -1, false, opts);
  }
});