define("cco-seek/initializers/active-model-adapter", ["exports", "active-model-adapter", "active-model-adapter/active-model-serializer"], function (_exports, _activeModelAdapter, _activeModelSerializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'active-model-adapter',
    initialize: function initialize() {
      var application = arguments[1] || arguments[0];
      application.register('adapter:-active-model', _activeModelAdapter.default);
      application.register('serializer:-active-model', _activeModelSerializer.default);
    }
  };
  _exports.default = _default;
});