define("cco-seek/components/display-detail", ["exports", "cco-seek/components/display-item"], function (_exports, _displayItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _displayItem.default.extend({
    actions: {
      back: function back() {
        this.sendAction('back');
      }
    }
  });

  _exports.default = _default;
});