define("cco-seek/adapters/application", ["exports", "active-model-adapter"], function (_exports, _activeModelAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _activeModelAdapter.default.extend({
    headers: {
      Accept: 'application/vnd.cco.io.v0.1+json',
      'X-Cco-Consumer': 'cco-seek'
    },
    host: EmberENV.ccoIoHost,
    shouldReloadAll: function shouldReloadAll() {
      return false;
    }
  });

  _exports.default = _default;
});