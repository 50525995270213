define("cco-seek/tests/factories/display", ["ember-data-factory-guy"], function (_emberDataFactoryGuy) {
  "use strict";

  _emberDataFactoryGuy.default.define('display', {
    default: {
      title: _emberDataFactoryGuy.default.generate(function (num) {
        return "Display ".concat(num);
      }),
      name: 'Display',
      facing: 'NE',
      geolocation: {
        latitude: 40.750356,
        longitude: -73.978142
      }
    }
  });
});