define("cco-seek/initializers/link-to-toggle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    application.inject('component:link-to-toggle', 'router', 'router:main');
  }

  var _default = {
    name: 'link-to-toggle',
    initialize: initialize
  };
  _exports.default = _default;
});