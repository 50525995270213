define("cco-seek/templates/components/copy-to-clipboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mR59dhcw",
    "block": "{\"symbols\":[],\"statements\":[[6,\"button\"],[10,\"class\",[26,[[18,\"buttonClassName\"]]]],[3,\"action\",[[19,0,[]],\"copy\"]],[7],[1,[18,\"label\"],false],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cco-seek/templates/components/copy-to-clipboard.hbs"
    }
  });

  _exports.default = _default;
});