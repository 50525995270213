define("cco-seek/breakpoints", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // When updating these one should also update values in
  // app/styles/mixins/respond-to.scss:
  var tabletWidth = 750;
  var desktopWidth = 1024;
  var _default = {
    mobile: "(max-width: ".concat(tabletWidth - 1, "px)"),
    tablet: "(min-width: ".concat(tabletWidth, "px) and ") + "(max-width: ".concat(desktopWidth - 1, "px)"),
    desktop: "(min-width: ".concat(desktopWidth, "px)")
  };
  _exports.default = _default;
});