define("cco-seek/components/filters-drawer", ["exports", "cco-seek/utils/rows-to-cols", "cco-seek/utils/parse-delimited-list", "cco-seek/utils/display-type"], function (_exports, _rowsToCols, _parseDelimitedList, _displayType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ProductProxy = Ember.ObjectProxy.extend({
    isChecked: true
  });

  var _default = Ember.Component.extend({
    isOpen: false,
    selectedFormat: '',
    init: function init() {
      this._super.apply(this, arguments);

      var displayType = this.get('model.displayType');

      if (displayType === _displayType.default.DIGITAL) {
        this.set('showDigitalDisplays', true);
      } else if (displayType === _displayType.default.STATIC) {
        this.set('showStaticDisplays', true);
      } else {
        this.set('showDigitalAndStaticDisplays', true);
      }

      var tabIdsStr = this.get('model.displayParams.tab_ids');

      if (tabIdsStr) {
        this.set('tabIds', (0, _parseDelimitedList.default)(tabIdsStr));
      }

      var numbersStr = this.get('model.displayParams.numbers');

      if (numbersStr) {
        this.set('displayNums', (0, _parseDelimitedList.default)(numbersStr));
      }
    },
    productCategories: function () {
      var products = this.get('model.products');
      return products ? this.getProductCategories(products) : null;
    }.property('model.products.[]'),
    getProductCategories: function getProductCategories(products) {
      var _this = this;

      var categories = {};
      products.forEach(function (product) {
        if (!categories[product.get('category')]) {
          categories[product.get('category')] = Ember.A([]);
        }

        categories[product.get('category')].push(product);
      });
      var categoriesArr = Ember.A([]);
      Object.keys(categories).forEach(function (category) {
        var filteredProductIds = Ember.A([]);

        if (_this.get('model.displayParams.product_ids')) {
          filteredProductIds = Ember.A(_this.get('model.displayParams.product_ids').split(','));
        }

        var proxiedProducts = categories[category].map(function (product) {
          var proxy = ProductProxy.create({
            content: product
          });
          var isChecked = !filteredProductIds.get('length') || filteredProductIds.includes(product.get('id'));
          proxy.set('isChecked', isChecked);
          return proxy;
        }).sortBy('name');
        var numColumns = _this.get('media.isMobile') ? 1 : 2;
        categoriesArr.push(Ember.Object.create({
          name: category,
          products: (0, _rowsToCols.default)(proxiedProducts, numColumns)
        }));
      });
      return categoriesArr;
    },
    actions: {
      show: function show() {
        this.set('isOpen', true);
        this.send('collapseAll');
      },
      hide: function hide() {
        this.set('isOpen', false);
      },
      didExpandCategory: function didExpandCategory(category) {
        this.get('productCategories').reject(function (cat) {
          return cat.get('name') === category.get('name');
        }).setEach('expanded', false);
      },
      collapseAll: function collapseAll() {
        this.get('productCategories').setEach('expanded', false);
      },
      applyFilters: function applyFilters() {
        var allProducts = this.get('model.products');
        var checkedProducts = this.get('productCategories').reduce(function (prev, curr) {
          return prev.concat(curr.products);
        }, Ember.A([])).filter(function (product) {
          return product.isChecked;
        });

        if (checkedProducts.get('length') === allProducts.get('length')) {
          checkedProducts.clear();
        }

        var displayType;
        var showDigitalAndStaticDisplays = this.get('showDigitalAndStaticDisplays');
        var showDigitalDisplays = this.get('showDigitalDisplays');
        var showStaticDisplays = this.get('showStaticDisplays');

        if (showDigitalAndStaticDisplays) {
          displayType = _displayType.default.BOTH;
        } else if (showDigitalDisplays) {
          displayType = _displayType.default.DIGITAL;
        } else if (showStaticDisplays) {
          displayType = _displayType.default.STATIC;
        }

        this.sendAction('apply', {
          products: checkedProducts,
          displayType: displayType,
          tabIds: this.get('tabIds'),
          displayNums: this.get('displayNums')
        });
        this.send('hide');
      }
    }
  });

  _exports.default = _default;
});