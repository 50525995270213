define("cco-seek/templates/components/link-to-strict", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xvzth3sV",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"link-to\",[[20,[\"route\"]]],[[\"tagName\"],[\"div\"]],{\"statements\":[[0,\"  \"],[11,1],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cco-seek/templates/components/link-to-strict.hbs"
    }
  });

  _exports.default = _default;
});