define("cco-seek/utils/error-alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

  var ErrorAlert = function ErrorAlert(title, message) {
    _classCallCheck(this, ErrorAlert);

    this.title = title;
    this.message = message;
  };

  _exports.default = ErrorAlert;
});