define("cco-seek/router", ["exports", "cco-seek/mixins/filter-blank-params-mixin", "cco-seek/config/environment"], function (_exports, _filterBlankParamsMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _hydrateUnsuppliedQueryParams;

  var Router = Ember.Router.extend(_filterBlankParamsMixin.default, {
    rootURL: _environment.default.rootURL,
    location: _environment.default.locationType,
    transitionTo: function transitionTo() {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      _hydrateUnsuppliedQueryParams = this._hydrateUnsuppliedQueryParams;
      var lastArg = args[args.length - 1];

      if (lastArg) {
        if (!lastArg.isSticky) {
          this._hydrateUnsuppliedQueryParams = function () {}; // eslint-disable-next-line no-prototype-builtins

        } else if (!lastArg.hasOwnProperty('queryParams')) {
          args = args.slice(0, args.length - 1);
        }
      }

      this._super.apply(this, _toConsumableArray(args));

      this._hydrateUnsuppliedQueryParams = _hydrateUnsuppliedQueryParams;
    },
    willTransition: function willTransition() {
      this._super.apply(this, arguments);

      var currentRoute = this.get('currentRoute');

      if (arguments.length) {
        var handlerInfo = arguments[arguments.length - 1];
        var routeName = handlerInfo.targetName;
        this.set('currentRoute', routeName);

        if (currentRoute) {
          this.set('previousRoute', currentRoute);
        }
      }
    }
  });
  Router.map(function () {
    this.route('results', function () {
      this.route('index', {
        path: ''
      }, function () {
        this.route('map');
        this.route('photosheet', {
          path: 'photosheet/:id'
        });
      });
    });
    this.route('my-displays', {});
    this.route('photosheet', {
      path: 'photosheet/:id'
    });
  });
  Router.reopen({
    notifyGoogleAnalytics: function () {
      if (_environment.default.environment === 'production') {
        return ga('send', 'pageview', {
          page: this.get('url'),
          title: this.get('url')
        });
      }
    }.on('didTransition')
  });
  var _default = Router;
  _exports.default = _default;
});