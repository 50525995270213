define("cco-seek/controllers/results/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['geo_id', 'latitude', 'longitude', 'product_ids', 'tab_ids', 'numbers', 'per_page', 'digital'],
    showContent: false,
    showContentFullHeight: true,
    actions: {
      showList: function showList() {
        this.set('showContent', false);
      },
      showContent: function showContent() {
        this.set('showContent', true);
      },
      showError: function showError(error) {
        this.set('error', error);
      },
      clearError: function clearError() {
        this.set('error', null);
      }
    }
  });

  _exports.default = _default;
});