define("cco-seek/templates/components/geo-locator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZjeqdrRX",
    "block": "{\"symbols\":[],\"statements\":[[6,\"a\"],[9,\"href\",\"#\"],[9,\"class\",\"current-location-link\"],[3,\"action\",[[19,0,[]],\"getCurrentLocation\"]],[7],[0,\"\\n  \"],[6,\"div\"],[10,\"class\",[26,[\"icon-container \",[25,\"if\",[[20,[\"isRetrieving\"]],\"spin\"],null]]]],[7],[0,\"\\n    \"],[6,\"span\"],[9,\"class\",\"cco-icon-current-location\"],[7],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cco-seek/templates/components/geo-locator.hbs"
    }
  });

  _exports.default = _default;
});