define("cco-seek/tests/factories/product", ["ember-data-factory-guy"], function (_emberDataFactoryGuy) {
  "use strict";

  _emberDataFactoryGuy.default.define('product', {
    default: {
      name: _emberDataFactoryGuy.default.generate(function (num) {
        return "Product ".concat(num);
      }),
      digital: false,
      category: 'Category 1'
    }
  });
});