define("cco-seek/utils/map/feature-types/display-type", ["exports", "cco-seek/utils/map/display-tooltip"], function (_exports, _displayTooltip) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getLayer = _exports.typeName = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var classNames = window.globals.utils.classNames;
  var getLeaflet = window.globals.utils.map.getLeaflet;
  var typeName = 'DISPLAY';
  _exports.typeName = typeName;

  function pointToLayer(data, latLng) {
    var display = data.properties.display;
    var facing = null;

    if (display && display.get('facing') && display.isFulfilled) {
      facing = display.get('facing').toLowerCase();
    }

    var icon = getLeaflet().divIcon({
      iconAnchor: [16, 28],
      className: classNames(_defineProperty({
        'display-marker': true,
        'no-facing': !facing
      }, "facing-".concat(facing), facing)),
      html: (0, _displayTooltip.default)(display.get('number'))
    });
    return getLeaflet().marker(latLng, {
      icon: icon
    });
  }

  var getLayer = function getLayer(f) {
    return getLeaflet().geoJson(f.getGeoJson(), {
      pointToLayer: pointToLayer
    });
  };

  _exports.getLayer = getLayer;
});