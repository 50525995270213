define("cco-seek/components/photosheet-back-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    targetRoute: function () {
      var previousRoute = this.get('router.previousRoute');
      var defaultRoute = 'results.index.index';

      if (this.get('media.isDesktop')) {
        return 'results.index.map';
      } else {
        return previousRoute ? previousRoute : defaultRoute;
      }
    }.property('router.previousRoute', 'media.isDesktop'),
    // As of Ember 2.10, link_to must provide a model if target route has a
    // dynamic segment (e.g. /foo/bar/:id). To workaround this we determine
    // if targetRoute has a dynamic segment, and if it does we can specify
    // `undefined` as the model:
    // https://github.com/emberjs/ember.js/issues/14657
    hasDynamicSegments: function () {
      var routes = this.get('router._routerMicrolib.recognizer.names');
      var route = routes[this.get('targetRoute')];
      var handlers = route.handlers; // Determine a DynamicSegment by the presence of a entry in `names`

      return handlers.some(function (handler) {
        return handler.names.length > 0;
      });
    }.property('router._routerMicrolib.recognizer.names', 'targetRoute')
  });

  _exports.default = _default;
});