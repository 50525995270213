define("cco-seek/transitions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default() {
    this.transition(this.fromRoute(null), this.toRoute('results.index.photosheet'), this.use('slideInRight', {
      duration: 400,
      easing: 'easeOutExpo'
    }), this.reverse('slideOutLeft', {
      duration: 400,
      easing: 'easeOutExpo'
    }));
    this.transition(this.fromRoute('results.searchBar'), this.toRoute(null), this.use('toUp', {
      duration: 500,
      easing: 'easeOutExpo'
    }), this.reverse('toDown', {
      duration: 500,
      delay: 200,
      easing: 'easeOutExpo'
    }));
    this.transition(this.hasClass('liquid-if-filters'), this.toValue(true), this.use('slideInDown', {
      duration: 500,
      easing: 'easeOutExpo'
    }), this.reverse('slideOutUp', {
      duration: 500,
      easing: 'easeOutExpo'
    }));
    this.transition(this.hasClass('liquid-if-menu'), this.toValue(true), this.use('toRight', {
      duration: 500,
      easing: 'easeOutExpo'
    }), this.reverse('toLeft', {
      duration: 500,
      easing: 'easeOutExpo'
    }));
    this.transition(this.hasClass('liquid-if-alerts'), this.toValue(true), this.use('toDown', {
      duration: 500,
      easing: 'easeOutExpo'
    }), this.reverse('toUp', {
      duration: 500,
      easing: 'easeOutExpo'
    }));
  }
});