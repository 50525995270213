define("cco-seek/utils/display-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DisplayType = {
    DIGITAL: 'DIGITAL',
    STATIC: 'STATIC',
    BOTH: 'BOTH',
    fromDigitalParam: function fromDigitalParam(param) {
      switch (param) {
        case 'true':
          return DisplayType.DIGITAL;

        case 'false':
          return DisplayType.STATIC;
      }
    },
    toDigitalParam: function toDigitalParam(displayType) {
      switch (displayType) {
        case DisplayType.DIGITAL:
          return 'true';

        case DisplayType.STATIC:
          return 'false';
      }
    }
  };
  var _default = DisplayType;
  _exports.default = _default;
});