define("cco-seek/routes/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    actions: {
      toggleMenu: function toggleMenu() {
        this.controllerFor('application').toggleProperty('menuOpen');
      },
      showMenu: function showMenu() {
        this.controllerFor('application').set('menuOpen', true);
      },
      hideMenu: function hideMenu() {
        this.controllerFor('application').set('menuOpen', false);
      }
    }
  });

  _exports.default = _default;
});