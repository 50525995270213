define("cco-seek/templates/preview-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5c7Tpy1K",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"alert info preview-message\"],[7],[0,\"\\n  \"],[6,\"h2\"],[7],[6,\"strong\"],[7],[0,\"Preview\"],[8],[0,\" Internal use only\"],[8],[0,\"\\n  \"],[6,\"div\"],[7],[0,\"\\n    \"],[6,\"p\"],[7],[0,\"\\n      Thanks for trying Seek! Please\\n      \"],[6,\"a\"],[9,\"href\",\"https://go.cco.io/SeekFeedback\"],[9,\"target\",\"_blank\"],[7],[0,\"give feedback\"],[8],[0,\"\\n       and vote on other ideas in Salesforce.\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cco-seek/templates/preview-message.hbs"
    }
  });

  _exports.default = _default;
});