define("cco-seek/templates/results/map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HXYm1xpU",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"map\"],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"shouldLoadMap\"]]],null,{\"statements\":[[0,\"    \"],[1,[25,\"seek-map\",null,[[\"center\",\"zoom\",\"view\",\"maxZoom\",\"featuresToFit\",\"features\",\"onBoundsChanged\",\"onClick\",\"onMouseOver\",\"onMouseOut\"],[[20,[\"center\"]],[20,[\"zoom\"]],[20,[\"view\"]],[20,[\"maxZoom\"]],[20,[\"featuresToFit\"]],[20,[\"features\",\"all\"]],\"handleBoundsChanged\",\"handleClickFeature\",\"handleMouseOverFeature\",\"handleMouseOutFeature\"]]],false],[0,\"\\n\"],[4,\"if\",[[20,[\"model\",\"geo\"]]],null,{\"statements\":[[0,\"      \"],[6,\"button\"],[9,\"class\",\"ghost-btn solid remove-boundary\"],[3,\"action\",[[19,0,[]],\"removeBoundary\"]],[7],[0,\"\\n        Remove Boundary\\n      \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cco-seek/templates/results/map.hbs"
    }
  });

  _exports.default = _default;
});