define("cco-seek/adapters/cart", ["exports", "ember-data", "cco-seek/constants"], function (_exports, _emberData, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var CartAdapter = _emberData.default.Adapter.extend({
    findAll: function findAll() {
      return new Ember.RSVP.Promise(function (resolve, reject) {
        try {
          var cartArray = localStorage.getItem(_constants.CART_LS_KEY);
          var payload = {
            data: cartArray ? JSON.parse(cartArray) : []
          };
          resolve(payload);
        } catch (e) {
          reject(new Error("Failed to parse cart data from local storage: ".concat(e.message)));
        }
      });
    },
    createRecord: function createRecord(_store, _type, snapshot) {
      return new Ember.RSVP.Promise(function (resolve, reject) {
        try {
          var json = snapshot.record.toJSON();
          var payload = {
            data: [json]
          };
          localStorage.setItem(_constants.CART_LS_KEY, JSON.stringify([json]));
          Ember.run(null, resolve, payload);
        } catch (e) {
          Ember.run(null, reject, new Error("Failed to persist record into localStorage: ".concat(e)));
        }
      });
    },
    updateRecord: function updateRecord() {
      return this.createRecord.apply(this, arguments);
    }
  });

  var _default = CartAdapter;
  _exports.default = _default;
});