define("cco-seek/components/geo-autocomplete", ["exports", "cco-seek/utils/error-alert", "cco-seek/utils/keymap"], function (_exports, _errorAlert, _keymap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PLACEHOLDER_TEXT = 'MARKET, CITY, or ZIP';
  var DEFAULT_MAX_NUM_SUGGESTIONS = 8;
  var GeoProxy = Ember.ObjectProxy.extend({
    stateCodes: function () {
      if (this.get('name')) {
        var matches = this.get('name').match(/ [A-Z]{2}/g) || [];
        return matches.map(function (match) {
          return match.trim();
        });
      } else {
        return [];
      }
    }.property('name'),
    hasStateCodeMatch: function hasStateCodeMatch(userInput) {
      var inputLowerCase = userInput.toLowerCase();
      var match = false;
      this.get('stateCodes').forEach(function (code) {
        match = match || code.toLowerCase() === inputLowerCase;
      });
      return match;
    },
    isHighlighted: false
  });

  var _default = Ember.Component.extend({
    classNames: ['geo-autocomplete'],
    classNameBindings: ['isError:error', 'isAutoPopulated:auto-populated'],
    inputVal: '',
    options: null,
    suggestions: Ember.A([]),
    highlightedIndex: 0,
    error: null,
    showCurrLocPlaceholder: false,
    isError: Ember.computed.bool('error'),
    isLocationSearch: false,
    isLoading: false,
    didInsertElement: function didInsertElement() {
      if (this.get('selected')) {
        this.set('inputVal', this.get('selected.name'));
      }
    },
    isAutoPopulated: function () {
      var selected = this.get('selected');
      var inputVal = this.get('inputVal');
      return selected ? selected.get('name') === inputVal : false;
    }.property('selected', 'inputVal'),
    handleHighlightedIndexChanged: function () {
      var _this = this;

      if (this.get('suggestions.length') > this.get('highlightedIndex')) {
        this.get('suggestions').forEach(function (suggestion, index) {
          suggestion.set('isHighlighted', index === _this.get('highlightedIndex'));
        });
      }
    }.observes('highlightedIndex', 'suggestions'),
    setSelected: function setSelected(geo) {
      this.set('selected', geo);
      this.set('inputVal', geo.get('name'));
      this.$('input').blur();
      this.sendAction('select', {
        geo: geo
      });
    },
    placeholder: function () {
      var isLocationSearch = this.get('isLocationSearch');
      var showCurrLocPlaceholder = this.get('showCurrLocPlaceholder');
      var hasLocateError = this.get('hasLocateError');
      return isLocationSearch || showCurrLocPlaceholder || hasLocateError ? 'Current location' : PLACEHOLDER_TEXT;
    }.property('isLocationSearch', 'showCurrLocPlaceholder', 'hasLocateError'),
    handleAPIError: function handleAPIError() {
      this.set('error', new _errorAlert.default("Bummer! We can't seem to access the database.", "This happens sometimes when we're unexpectedly popular.  " + 'The team behind Seek is working on a fix so please try ' + 'again in 5-10 minutes.'));
    },
    clearError: function clearError() {
      this.set('error', null);
      this.set('hasLocateError', false);
    },
    actions: {
      showSuggestions: function showSuggestions() {
        var _this2 = this;

        if (this.get('inputVal.length') > 2 && this.get('selected.name') !== this.get('inputVal')) {
          this.set('isLoading', true);

          try {
            this.get('options').then(function (results) {
              results = results.map(function (geo) {
                return GeoProxy.create({
                  content: geo
                });
              });
              var filteredResults = results.filter(function (geo) {
                var nameLowerCase = geo.get('name').toLowerCase();

                var inputLowerCase = _this2.get('inputVal').toLowerCase();

                return nameLowerCase.indexOf(inputLowerCase) > -1;
              }); // Sort so that suggestions that match by state code are at top:

              var stateMatches = [];
              var nameMatches = [];
              filteredResults.forEach(function (geo) {
                if (geo.hasStateCodeMatch(_this2.get('inputVal'))) {
                  stateMatches.push(geo);
                } else {
                  nameMatches.push(geo);
                }
              });
              var orderedResults = stateMatches.concat(nameMatches);
              var maxSuggestions = _this2.get('maxSuggestions') || DEFAULT_MAX_NUM_SUGGESTIONS;
              orderedResults = orderedResults.slice(0, maxSuggestions);

              _this2.set('highlightedIndex', 0);

              _this2.set('suggestions', orderedResults);
            }).catch(function () {
              return _this2.handleAPIError();
            }).finally(function () {
              _this2.set('isLoading', false);
            });
          } catch (e) {
            this.handleAPIError();
          }
        } else {
          this.send('clearSuggestions');
        }
      },
      clearSuggestions: function clearSuggestions() {
        // Run async to avoid certain browser event race conditions
        Ember.run.later(this, function () {
          if (this.get('suggestions')) {
            this.get('suggestions').clear();
          }
        }, 0);
      },
      selectHighlighted: function selectHighlighted() {
        var isLoading = this.get('isLoading');
        var selectedName = this.get('selected.name');
        var inputVal = this.get('inputVal');

        if (this.get('suggestions.length') > 0) {
          var index = this.get('highlightedIndex');
          this.setSelected(this.get('suggestions').objectAt(index));
          this.send('clearSuggestions');
        } else if (!isLoading && selectedName !== inputVal) {
          this.set('error', new _errorAlert.default('We are not sure what location you are searching for!', "We're not able to find any DMAs, CBSAs, Counties or Zip Codes " + 'that match. Please try again.'));
        }
      },
      select: function select(geo) {
        this.setSelected(geo);
        this.send('clearSuggestions');
      },
      handleKeyUp: function handleKeyUp(value, event) {
        if (!(event.keyCode === _keymap.default.UP || event.keyCode === _keymap.default.DOWN)) {
          Ember.run.debounce(this, this.actions.showSuggestions, 200);
        }

        var prevValue = this.get('prevInputVal');

        if (prevValue === undefined || value !== prevValue) {
          this.send('handleChange', value);
        }

        this.set('prevInputVal', value);
      },
      handleKeyDown: function handleKeyDown(_value, event) {
        var highlightedIndex = this.get('highlightedIndex');
        var suggestionsLength = this.get('suggestions.length');
        var supportedKeyCodes = Ember.A([_keymap.default.UP, _keymap.default.DOWN, _keymap.default.ENTER]);

        if (supportedKeyCodes.includes(event.keyCode)) {
          event.preventDefault();
        }

        if (event.keyCode === _keymap.default.ENTER) {
          this.send('selectHighlighted');
        } else if (event.keyCode === _keymap.default.UP && highlightedIndex > 0) {
          // up arrow
          this.decrementProperty('highlightedIndex');
        } else if (event.keyCode === _keymap.default.DOWN && highlightedIndex < suggestionsLength - 1) {
          // down arrow
          this.incrementProperty('highlightedIndex');
        }
      },
      handleChange: function handleChange() {
        this.clearError();
      },
      handleFocusIn: function handleFocusIn() {
        this.send('showSuggestions');
        var inputVal = this.get('inputVal');
        var selectedName = this.get('selected.name');

        if (inputVal === selectedName) {
          this.set('inputVal', '');
        }
      },
      handleFocusOut: function handleFocusOut() {
        this.send('clearSuggestions');

        if (!this.get('inputVal')) {
          this.set('inputVal', this.get('selected.name'));
        }
      },
      onLocateStart: function onLocateStart() {
        this.set('isLocationSearch', true);
      },
      setLocation: function setLocation(location) {
        this.set('isLocationSearch', true);
        this.set('location', location);
        this.set('selected', null);
        this.sendAction('select', {
          location: location
        });
      },
      onLocateError: function onLocateError() {
        this.set('isLocationSearch', false);
        var error = new _errorAlert.default("We can't seem to locate you...", 'Please be sure location services are enabled on your device ' + 'and allow the app to access your current location.');
        this.set('error', error);
        this.set('hasLocateError', true);
        this.sendAction('onLocateError');
      }
    }
  });

  _exports.default = _default;
});