define("cco-seek/utils/map/feature-types/geo-boundary-type", ["exports", "cco-seek/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getLayer = _exports.typeName = void 0;
  var getLeaflet = window.globals.utils.map.getLeaflet;
  var typeName = 'GEO_BOUNDARY';
  _exports.typeName = typeName;

  var getLayer = function getLayer(f) {
    return getLeaflet().geoJson(f.getGeoJson(), {
      style: function style() {
        return {
          fillOpacity: 0.2,
          fillColor: _constants.Color.CCO_DARK_AQUA,
          weight: 2,
          color: _constants.Color.CCO_DARK_AQUA,
          opacity: 0.5
        };
      }
    });
  };

  _exports.getLayer = getLayer;
});