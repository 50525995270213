define("cco-seek/utils/parse-delimited-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.parseDelimitedList = parseDelimitedList;
  _exports.default = void 0;

  function parseDelimitedList(str) {
    var delimiter = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : ',';
    return str.length ? str.split(delimiter).map(function (value) {
      return value.trim();
    }) : [];
  }

  var _default = parseDelimitedList;
  _exports.default = _default;
});