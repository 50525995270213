define("cco-seek/utils/format-number", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatNumber = formatNumber;
  _exports.default = void 0;

  function numberWithCommas(num) {
    var parts = num.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
  }

  function formatNumber(value, numDecPlaces) {
    if (value) {
      var num = parseFloat(value);
      var fixed = numDecPlaces ? num.toFixed(numDecPlaces) : num;
      return numberWithCommas(fixed);
    } else {
      return '';
    }
  }

  var _default = formatNumber;
  _exports.default = _default;
});