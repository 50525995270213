define("cco-seek/utils/displays-filter-mode", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DisplaysFilterMode = {
    TAB_IDS: 'TAB_IDS',
    DISPLAY_NUMS: 'DISPLAY_NUMS'
  };
  var _default = DisplaysFilterMode;
  _exports.default = _default;
});