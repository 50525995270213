define("cco-seek/serializers/cluster", ["exports", "cco-seek/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    modelNameFromPayloadKey: function modelNameFromPayloadKey(payloadKey) {
      return this._super(payloadKey, 'cluster');
    },
    normalizeResponse: function normalizeResponse(_store, _primaryModelClass, payload) {
      if (Ember.testing && !payload.data) {
        payload.data = payload.clusters;
        delete payload.clusters;
      }

      return this._super.apply(this, arguments);
    },
    extractId: function extractId(_modelClass, resourceHash) {
      return Ember.guidFor(resourceHash);
    }
  });

  _exports.default = _default;
});