define("cco-seek/components/copy-to-clipboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['copy-to-clipboard'],
    copyToClipboardService: Ember.inject.service('copy-to-clipboard'),
    actions: {
      copy: function copy() {
        var copyToClipboardFn = this.get('copyToClipboardService.copyToClipboard');

        if (copyToClipboardFn(this.get('content'))) {
          this.send('success');
        } else {
          this.send('error');
        }
      },
      success: function success() {
        var _this = this;

        var buttonEl = this.$('button').get(0);
        typeOut('Copied!', buttonEl, 30, function () {
          Ember.run.later(_this, typeOut, _this.get('label'), buttonEl, 10, 1000);
        });
      },
      error: function error() {
        console.error('Copy failed');
        var buttonEl = this.$('button').get(0);
        typeOut('Not supported', buttonEl, 30);
      }
    }
  });
  /**
   * typeOut writes the string str into the textContent of the element el at
   * millisecond intervals given by speed.
   */


  _exports.default = _default;

  function typeOut(str, el, speed) {
    var cb = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : function () {};
    // clear the current string first. If there is no content this is the same as
    // just calling type.
    Ember.run.later(this, clear, el, str, 0, speed, cb, speed);
  } // type out the new string character by chracter.


  function type(el, str, i, speed, cb) {
    el.textContent += str[i];

    if (str.length - 1 > i) {
      Ember.run.later(this, type, el, str, i + 1, speed, cb, speed);
    } else {
      if (undefined !== cb) {
        cb();
      }
    }
  } // delete the current string character by character.


  function clear(el, str, i, speed, cb) {
    if (el.textContent.length > 0 && el.innerHTML !== '') {
      var newContent = el.textContent.substr(0, el.textContent.length - 1);
      el.innerHTML = newContent;
      Ember.run.later(this, clear, el, str, i, speed, cb, speed);
    } else {
      Ember.run.later(this, type, el, str, i, speed, cb, speed);
    }
  }
});