define("cco-seek/components/link-to-toggle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    classNameBindings: ['isActive:active'],
    isActive: function () {
      var route = this.get('toRoute');
      var model = this.get('model');
      var router = this.get('router');
      return router.isActive(route, model);
    }.property('toRoute', 'model', 'router.url')
  });

  _exports.default = _default;
});