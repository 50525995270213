define("cco-seek/helpers/format-number", ["exports", "cco-seek/utils/format-number"], function (_exports, _formatNumber) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatNumber = formatNumber;
  _exports.default = void 0;

  function formatNumber(value, _ref) {
    var numDecPlaces = _ref.numDecPlaces;
    return (0, _formatNumber.default)(value, numDecPlaces);
  }

  var _default = Ember.Helper.helper(formatNumber);

  _exports.default = _default;
});