define("cco-seek/templates/components/displays-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GvE6y+nJ",
    "block": "{\"symbols\":[\"display\"],\"statements\":[[4,\"each\",[[20,[\"displays\"]]],[[\"key\"],[\"@index\"]],{\"statements\":[[4,\"link-to-toggle\",null,[[\"toRoute\",\"model\",\"fromRoute\",\"class\"],[\"results.index.photosheet\",[19,1,[]],\"results.index.map\",\"display-compact\"]],{\"statements\":[[0,\"    \"],[1,[25,\"display-item\",null,[[\"model\",\"loadMap\"],[[19,1,[]],false]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cco-seek/templates/components/displays-list.hbs"
    }
  });

  _exports.default = _default;
});