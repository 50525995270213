define("cco-seek/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.CURRENT_LOC_ZOOM_LEVEL = _exports.DEFAULT_MAP_CENTER = _exports.USA_BOUNDS = _exports.DEFAULT_SEARCH_RADIUS = _exports.CART_LS_KEY = _exports.Color = void 0;
  var Color = {
    WHITE: '#ffffff',
    CCO_BRIGHT_AQUA: '#33c1d2',
    CCO_DARK_AQUA: '#004651'
  };
  _exports.Color = Color;
  var CART_LS_KEY = 'seek-cart-data'; // Map constants:

  _exports.CART_LS_KEY = CART_LS_KEY;
  var DEFAULT_SEARCH_RADIUS = 10;
  _exports.DEFAULT_SEARCH_RADIUS = DEFAULT_SEARCH_RADIUS;
  var USA_BOUNDS = [{
    lat: 25.82,
    lng: -124.39
  }, {
    lat: 49.38,
    lng: -66.94
  }];
  _exports.USA_BOUNDS = USA_BOUNDS;
  var DEFAULT_MAP_CENTER = {
    lat: 41.057341,
    lng: -99.686299
  };
  _exports.DEFAULT_MAP_CENTER = DEFAULT_MAP_CENTER;
  var CURRENT_LOC_ZOOM_LEVEL = 11;
  _exports.CURRENT_LOC_ZOOM_LEVEL = CURRENT_LOC_ZOOM_LEVEL;
});