define("cco-seek/services/cart-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    cart: null,
    getDisplays: function getDisplays() {
      var _this = this;

      return this.store.findAll('cart').then(function (cartRecords) {
        if (cartRecords.get('length')) {
          var cart = cartRecords.get('firstObject');
          var displayIds = cart.get('displays');
          return _this.store.query('display', {
            ids: displayIds.join(',')
          });
        } else {
          return Ember.A([]);
        }
      });
    },
    numDisplays: function () {
      return this.get('cart.displays.length');
    }.property('cart.displays.[]'),
    init: function init() {
      var _this2 = this;

      this._super.apply(this, arguments);

      this.store.findAll('cart').then(function (records) {
        if (records.get('length') > 0) {
          _this2.set('cart', records.get('firstObject'));
        }
      });
    },
    getCart: function getCart() {
      return this.get('cart') || this.createCart();
    },
    createCart: function createCart() {
      var cart = this.store.createRecord('cart');
      cart.set('id', 1);
      cart.save();
      this.set('cart', cart);
      return cart;
    },
    addToCart: function addToCart(display) {
      var cart = this.getCart();
      var displays = this.get('cart.displays');
      displays.addObject(display.get('id'));
      return cart.save();
    },
    removeFromCart: function removeFromCart(display) {
      var cart = this.getCart();
      var displays = cart.get('displays');
      displays.removeObject(display.get('id'));
      return cart.save();
    },
    removeAllDisplays: function removeAllDisplays() {
      var cart = this.getCart();
      cart.set('displays', Ember.A([]));
      return cart.save();
    },
    displayInCart: function displayInCart(display) {
      if (this.get('cart')) {
        var id = display.get('id');
        return this.get('cart.displays').includes(id);
      }
    }
  });

  _exports.default = _default;
});