define("cco-seek/templates/components/product-category", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iwUOxB9g",
    "block": "{\"symbols\":[\"product\"],\"statements\":[[6,\"fieldset\"],[10,\"class\",[26,[\"product-category \",[25,\"if\",[[20,[\"expanded\"]],\"expanded\"],null]]]],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"header\"],[7],[0,\"\\n    \"],[6,\"label\"],[9,\"class\",\"cb select-all\"],[7],[0,\"\\n      \"],[1,[25,\"input\",null,[[\"type\",\"checked\",\"indeterminate\"],[\"checkbox\",[20,[\"selectAllVal\"]],[20,[\"indeterminate\"]]]]],false],[0,\"\\n      \"],[6,\"span\"],[9,\"class\",\"cb-icon\"],[7],[8],[0,\"\\n      \"],[6,\"span\"],[9,\"class\",\"text\"],[7],[1,[20,[\"model\",\"name\"]],false],[8],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"button\"],[10,\"class\",[26,[[25,\"if\",[[20,[\"expanded\"]],\"less-btn\"],null],\" \",[25,\"unless\",[[20,[\"expanded\"]],\"more-btn\"],null]]]],[3,\"action\",[[19,0,[]],\"toggleExpanded\"]],[7],[0,\"\\n\"],[4,\"if\",[[20,[\"expanded\"]]],null,{\"statements\":[[0,\"        Less\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        More\\n\"]],\"parameters\":[]}],[0,\"    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\\n\"],[4,\"if\",[[20,[\"expanded\"]]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[9,\"class\",\"products\"],[7],[0,\"\\n\"],[4,\"each\",[[20,[\"model\",\"products\"]]],null,{\"statements\":[[0,\"      \"],[6,\"label\"],[9,\"class\",\"cb\"],[7],[0,\"\\n        \"],[1,[25,\"input\",null,[[\"type\",\"checked\"],[\"checkbox\",[19,1,[\"isChecked\"]]]]],false],[0,\"\\n        \"],[6,\"span\"],[9,\"class\",\"cb-icon\"],[7],[8],[0,\"\\n        \"],[6,\"span\"],[9,\"class\",\"text\"],[7],[1,[19,1,[\"name\"]],false],[8],[0,\"\\n      \"],[8],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cco-seek/templates/components/product-category.hbs"
    }
  });

  _exports.default = _default;
});