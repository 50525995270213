define("cco-seek/tests/factories/cluster", ["ember-data-factory-guy"], function (_emberDataFactoryGuy) {
  "use strict";

  _emberDataFactoryGuy.default.define('cluster', {
    default: {
      count: 3,
      center: {
        latitude: '40.750356',
        longitude: '-73.978142'
      }
    },
    traits: {
      singleDisplay: {
        count: 1
      }
    }
  });
});