define("cco-seek/templates/components/photosheet-back-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UaS+448t",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[20,[\"hasDynamicSegments\"]]],null,{\"statements\":[[4,\"link-to\",[[20,[\"targetRoute\"]],[24]],null,{\"statements\":[[0,\"    \"],[6,\"span\"],[9,\"class\",\"cco-icon-arrow-left-box\"],[7],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[4,\"link-to\",[[20,[\"targetRoute\"]]],null,{\"statements\":[[0,\"    \"],[6,\"span\"],[9,\"class\",\"cco-icon-arrow-left-box\"],[7],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cco-seek/templates/components/photosheet-back-button.hbs"
    }
  });

  _exports.default = _default;
});