define("cco-seek/models/display", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    cart: _emberData.default.belongsTo('cart'),
    alternate: _emberData.default.attr('boolean'),
    alternatePosition: _emberData.default.attr('number'),
    baseRate: _emberData.default.attr('number'),
    city: _emberData.default.attr('string'),
    county: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    digital: _emberData.default.attr('boolean'),
    digitalSlotCount: _emberData.default.attr('number'),
    digitalSlotLength: _emberData.default.attr('string'),
    facing: _emberData.default.attr('string'),
    geolocation: _emberData.default.attr(),
    illuminated: _emberData.default.attr('boolean'),
    illuminatedHours: _emberData.default.attr('number'),
    installCost: _emberData.default.attr('number'),
    market: _emberData.default.attr('string'),
    mediaType: _emberData.default.attr('string'),
    mediaTypeDetail: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    number: _emberData.default.attr('string'),
    photoSheetUrl: _emberData.default.attr('string'),
    photoThumbUrl: _emberData.default.attr('string'),
    photoUrl: _emberData.default.attr('string'),
    primaryArtery: _emberData.default.attr('string'),
    productionCost: _emberData.default.attr('number'),
    rateCardPrice: _emberData.default.attr('number'),
    read: _emberData.default.attr('string'),
    secondaryArtery: _emberData.default.attr('string'),
    state: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    tabId: _emberData.default.attr('number'),
    title: _emberData.default.attr('string'),
    unitSize: _emberData.default.attr(),
    visibleAdSize: _emberData.default.attr(),
    webCamUrl: _emberData.default.attr('string'),
    weeklyImpressions18Plus: _emberData.default.attr('number'),
    zip: _emberData.default.attr('string')
  });

  _exports.default = _default;
});