define("cco-seek/components/product-category", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['product-category-container'],
    classNameBindings: ['expanded'],
    expanded: Ember.computed.alias('model.expanded'),
    indeterminate: false,
    selectAllValDidChange: function () {
      if (this.get('selectAllVal')) {
        this.selectAll();
      } else {
        this.unselectAll();
      }

      this.set('indeterminate', false);
    }.observes('selectAllVal'),
    productsCheckedDidChange: function () {
      if (this.get('model.products')) {
        var numProducts = this.get('model.products.length');
        var numSelected = this.get('model.products').filter(function (p) {
          return p.get('isChecked');
        }).length;

        if (numSelected === numProducts) {
          this.set('indeterminate', false);
          this.set('selectAllVal', true);
        } else if (numSelected > 0) {
          this.set('indeterminate', true);
        } else {
          this.set('indeterminate', false);
          this.set('selectAllVal', false);
        }
      }
    }.observes('model.products.@each.isChecked').on('init'),
    selectAll: function selectAll() {
      this.get('model.products').forEach(function (product) {
        return product.set('isChecked', true);
      });
    },
    unselectAll: function unselectAll() {
      this.get('model.products').forEach(function (product) {
        return product.set('isChecked', false);
      });
    },
    actions: {
      toggleExpanded: function toggleExpanded() {
        this.toggleProperty('expanded');

        if (this.get('expanded')) {
          this.sendAction('onExpand', this.get('model'));
        }
      }
    }
  });

  _exports.default = _default;
});