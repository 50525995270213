define("cco-seek/utils/map/feature-types/cluster-type", ["exports", "cco-seek/utils/map/cluster-icon"], function (_exports, _clusterIcon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getLayer = _exports.typeName = void 0;
  var getLeaflet = window.globals.utils.map.getLeaflet;
  var typeName = 'CLUSTER';
  _exports.typeName = typeName;

  function pointToLayer(data, latLng) {
    var count = data.properties.count;
    var size = (0, _clusterIcon.getSize)(count);
    var icon = getLeaflet().icon({
      iconAnchor: [size / 2, size / 2],
      iconSize: [size, size],
      iconUrl: (0, _clusterIcon.getClusterIconUri)(count),
      className: 'marker booking-cluster'
    });
    return getLeaflet().marker(latLng, {
      icon: icon
    });
  }

  var getLayer = function getLayer(f) {
    return getLeaflet().geoJson(f.getGeoJson(), {
      pointToLayer: pointToLayer
    });
  };

  _exports.getLayer = getLayer;
});