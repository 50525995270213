define("cco-seek/components/seek-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _Ember;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _window$globals = window.globals,
      React = _window$globals.React,
      ReactDOM = _window$globals.ReactDOM;
  var mapProps = ['center', 'zoom', 'view', 'maxZoom', 'featuresToFit', 'features', 'bounds', 'updateMapPropsOnLoad'];
  var mapEvents = ['onBoundsChanged', 'onClick'];

  var _default = Ember.Component.extend({
    rendered: false,
    renderReact: function renderReact() {
      var _this = this;

      this.set('rendered', true);
      var reactComponent = window.globals.components.MapboxMap;

      if (!reactComponent) {
        throw new Error('window.globals.components.MapboxMap does not exist');
      }

      var handlers = mapEvents.reduce(function (prev, curr) {
        return _objectSpread(_objectSpread({}, prev), {}, _defineProperty({}, curr, function () {
          for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
          }

          return _this.sendAction.apply(_this, [curr].concat(args));
        }));
      }, {});

      var props = _objectSpread(_objectSpread({
        accessToken: EmberENV.mapboxToken,
        zoomControl: false
      }, this.getProperties.apply(this, mapProps)), handlers);

      var reactElement = React.createElement(reactComponent, props);
      ReactDOM.render(reactElement, this.get('element'));
    },
    didInsertElement: function didInsertElement() {
      this.renderReact();
    },
    propsDidChange: (_Ember = Ember).observer.apply(_Ember, mapProps.concat([function () {
      if (this.get('rendered')) {
        this.renderReact();
      }
    }]))
  });

  _exports.default = _default;
});