define("cco-seek/routes/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    renderTemplate: function renderTemplate() {
      this.render('application');
      this.render('landing', {
        into: 'application'
      });
    }
  });

  _exports.default = _default;
});