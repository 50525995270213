define("cco-seek/controllers/results/map", ["exports", "cco-seek/constants", "cco-seek/utils/map/feature-types"], function (_exports, _constants, _featureTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.CURRENT_LOC_ZOOM_LEVEL = void 0;
  var FeatureType = (0, _featureTypes.getFeatureType)();
  var Feature = window.globals.utils.map.Feature;
  var _window$globals$utils = window.globals.utils.geoHelpers,
      formatLatLng = _window$globals$utils.formatLatLng,
      pointToLatLng = _window$globals$utils.pointToLatLng;
  var DEFAULT_ZOOM_LEVEL = 5;
  var MAX_ZOOM_LEVEL = 21;
  var CLUSTER_ZOOM_DELTA = 2;
  var CURRENT_LOC_ZOOM_LEVEL = 11;
  _exports.CURRENT_LOC_ZOOM_LEVEL = CURRENT_LOC_ZOOM_LEVEL;
  var Features = Ember.Object.extend({
    all: function () {
      return Ember.A([this.get('geoBoundaries'), this.get('clusterFan'), this.get('displayTooltip'), this.get('currentLocation')]).concat(this.get('clusters') || Ember.A([])).compact();
    }.property('clusters', 'geoBoundaries', 'clusterFan', 'displayTooltip', 'currentLocation')
  });

  var _default = Ember.Controller.extend({
    markerClusterer: Ember.inject.service('marker-clusterer'),
    resultsController: Ember.inject.controller('results/index'),
    center: _constants.DEFAULT_MAP_CENTER,
    zoom: DEFAULT_ZOOM_LEVEL,
    maxZoom: MAX_ZOOM_LEVEL,
    features: Features.create(),
    hasLoadedMap: false,
    shouldLoadMap: function () {
      var shouldLoadMap = this.get('resultsController.showContent') || this.get('media.isDesktop');
      if (shouldLoadMap) this.set('hasLoadedMap', true);
      return shouldLoadMap || this.get('hasLoadedMap');
    }.property('resultsController.showContent', 'media.isDesktop'),
    updateClusters: function updateClusters(bounds) {
      var _this = this;

      var markerClusterer = this.get('markerClusterer');
      var displayParams = this.get('model.displayParams');
      var isDesktop = this.get('media').isDesktop;

      if (displayParams) {
        this.get('markerClusterer').unloadAll();
        Ember.run.next(function () {
          markerClusterer.getClusterFeatures(displayParams, bounds, isDesktop).then(function (features) {
            return _this.set('features.clusters', features);
          });
        });
      }
    },
    showClusterFan: function showClusterFan(point, count, displayIds) {
      var _this2 = this;

      var promises = displayIds.map(function (id) {
        return _this2.store.find('display', id);
      });
      Ember.RSVP.all(promises).then(function (displays) {
        var clusterFan = new Feature(FeatureType.CLUSTER_FAN, point, {
          count: count,
          displays: displays
        });

        _this2.set('features.clusterFan', clusterFan);
      });
    },
    displayParamsDidChange: function () {
      var bounds = this.get('currentBounds');

      if (bounds) {
        Ember.run.once(this, 'updateClusters', bounds);
      }
    }.observes('model.displayParams'),
    didPerformLocationSearch: function () {
      var lat = this.get('model.latitude');
      var lng = this.get('model.longitude');
      var isLocationSearch = this.get('model.isLocationSearch');

      if (isLocationSearch && lat && lng) {
        lat = Number(lat);
        lng = Number(lng);
        var feature = new Feature(FeatureType.CURRENT_LOCATION, {
          type: 'Point',
          coordinates: [lng, lat]
        });
        this.set('featuresToFit', null);
        this.set('features.geoBoundaries', null);
        this.set('features.currentLocation', feature);
        this.set('zoom', CURRENT_LOC_ZOOM_LEVEL);
        this.set('center', {
          lat: lat,
          lng: lng
        });
      }
    }.observes('model.isLocationSearch', 'model.latitude', 'model.longitude'),
    drawGeoBoundaries: function () {
      var _this3 = this;

      Ember.run.once(function () {
        var geoBoundaries = _this3.get('model.geo.boundaries');

        var isLocationSearch = _this3.get('model.isLocationSearch');

        if (!isLocationSearch && geoBoundaries) {
          var feature = new Feature(FeatureType.GEO_BOUNDARY, geoBoundaries);

          _this3.set('features.clusterFan', null);

          _this3.set('features.currentLocationMarker', null);

          _this3.set('features.geoBoundaries', feature);

          _this3.set('featuresToFit', [feature]);
        }
      });
    }.observes('model.isLocationSearch', 'model.geo.boundaries'),
    didUnsetGeo: function () {
      if (!this.get('model.geo')) {
        this.set('features.geoBoundaries', null);
        this.set('featuresToFit', null);
      }
    }.observes('model.geo'),
    updateSearchBounds: function updateSearchBounds(bounds) {
      this.send('updateSearchBounds', {
        ne: formatLatLng(bounds.getNorthEast()),
        sw: formatLatLng(bounds.getSouthWest())
      });
    },
    actions: {
      removeBoundary: function removeBoundary() {
        this.send('clearGeo');
      },
      handleBoundsChanged: function handleBoundsChanged(bounds) {
        this.set('currentBounds', bounds);
        Ember.run.debounce(this, 'updateClusters', bounds, 150);
        Ember.run.debounce(this, 'updateSearchBounds', bounds, 150);
      },
      handleClickFeature: function handleClickFeature(e) {
        var _this4 = this;

        var feature = e.feature;

        switch (feature.type) {
          case FeatureType.DISPLAY:
            {
              var displayPromise = feature.properties.display;
              displayPromise.then(function (display) {
                _this4.send('showPhotosheet', display.get('id'));

                _this4.set('features.displayTooltip', null);
              });
              break;
            }

          case FeatureType.CLUSTER:
            {
              var count = feature.properties.count;

              if (count <= 5) {
                if (!this.get('features.clusterFan')) {
                  this.set('features.clusterFan', null);
                  this.showClusterFan(feature.geometry, count, feature.properties.displayIds);
                } else {
                  this.set('features.clusterFan', null);
                }
              } else {
                var newZoom = e.target.getZoom() + CLUSTER_ZOOM_DELTA;
                this.set('view', {
                  center: pointToLatLng(feature.geometry),
                  zoom: Math.min(newZoom, MAX_ZOOM_LEVEL)
                });
              }

              break;
            }

          case FeatureType.CLUSTER_FAN:
            {
              if (e.displayId) {
                this.send('showPhotosheet', e.displayId);
                this.set('features.displayTooltip', null);
              } else {
                this.set('features.clusterFan', null);
              }
            }
        }
      }
    }
  });

  _exports.default = _default;
});