define("cco-seek/transitions/slide-in-right", ["exports", "cco-seek/transitions/slide-over"], function (_exports, _slideOver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = slideInRight;

  function slideInRight(opts) {
    return _slideOver.default.call(this, 'x', 1, true, opts);
  }
});