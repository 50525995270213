define("cco-seek/controllers/results/displays/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    resultsController: Ember.inject.controller('results/index'),
    error: Ember.computed.alias('resultsController.error'),
    count: function () {
      return this.get('model.displays.length');
    }.property('model.displays.length')
  });

  _exports.default = _default;
});