define("cco-seek/serializers/cart", ["exports", "cco-seek/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    modelNameFromPayloadKey: function modelNameFromPayloadKey(payloadKey) {
      return this._super(payloadKey, 'cart');
    },
    extractId: function extractId(_modelClass, resourceHash) {
      return Ember.guidFor(resourceHash);
    }
  });

  _exports.default = _default;
});