define("cco-seek/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pe7jCyL/",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"container\"],[7],[0,\"\\n  \"],[1,[25,\"liquid-outlet\",null,[[\"class\"],[\"fill-screen\"]]],false],[0,\"\\n  \"],[6,\"div\"],[10,\"class\",[26,[[25,\"unless\",[[20,[\"menuOpen\"]],\"no-pointer\"],null]]]],[7],[0,\"\\n\"],[4,\"liquid-if\",[[20,[\"menuOpen\"]]],[[\"class\"],[\"liquid-if-menu fill-screen\"]],{\"statements\":[[0,\"      \"],[12,\"menu\",[]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":true}",
    "meta": {
      "moduleName": "cco-seek/templates/application.hbs"
    }
  });

  _exports.default = _default;
});