define("cco-seek/components/link-to-strict", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: ['isActive:active'],
    tagName: 'a',
    isActive: Ember.computed('router', 'router.url', 'route', function () {
      var route = this.get('route');
      var router = this.get('router');

      if (route && router) {
        return router.isActive(route);
      } else {
        return false;
      }
    })
  });

  _exports.default = _default;
});