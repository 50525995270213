define("cco-seek/templates/components/photosheet-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Kx+dQXhi",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[20,[\"photoSheetUrl\"]]],null,{\"statements\":[[0,\"  \"],[6,\"button\"],[9,\"type\",\"button\"],[9,\"class\",\"ghost-btn dropdown-btn\"],[9,\"aria-controls\",\"photosheet-dropdown\"],[7],[0,\"\\n    \"],[1,[18,\"label\"],false],[0,\"\\n  \"],[8],[0,\"\\n  \"],[6,\"ul\"],[10,\"class\",[26,[\"photosheet-menu \",[25,\"if\",[[20,[\"includeUnique\"]],\"with-unique\"],null]]]],[7],[0,\"\\n    \"],[6,\"li\"],[7],[0,\"\\n      \"],[6,\"a\"],[10,\"href\",[26,[[18,\"photoSheetUrl\"]]]],[9,\"target\",\"_blank\"],[7],[0,\"Photosheet + Specsheet\"],[8],[0,\"\\n    \"],[8],[0,\"\\n\"],[4,\"if\",[[20,[\"includeUnique\"]]],null,{\"statements\":[[0,\"      \"],[6,\"li\"],[7],[0,\"\\n        \"],[6,\"a\"],[10,\"href\",[26,[[18,\"uniquePhotoSheetUrl\"]]]],[9,\"target\",\"_blank\"],[7],[0,\"Photosheet + Specsheet (Unique)\"],[8],[0,\"\\n      \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[6,\"li\"],[7],[0,\"\\n      \"],[6,\"a\"],[10,\"href\",[26,[[18,\"specSheetOnlyUrl\"]]]],[9,\"target\",\"_blank\"],[7],[0,\"Specsheet Only\"],[8],[0,\"\\n    \"],[8],[0,\"\\n\"],[4,\"if\",[[20,[\"includeUnique\"]]],null,{\"statements\":[[0,\"      \"],[6,\"li\"],[7],[0,\"\\n        \"],[6,\"a\"],[10,\"href\",[26,[[18,\"uniqueSpecSheetOnlyUrl\"]]]],[9,\"target\",\"_blank\"],[7],[0,\"Specsheet Only (Unique)\"],[8],[0,\"\\n      \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[6,\"li\"],[7],[0,\"\\n      \"],[6,\"a\"],[10,\"href\",[26,[[18,\"photoSheetOnlyUrl\"]]]],[9,\"target\",\"_blank\"],[7],[0,\"Photosheet Only\"],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cco-seek/templates/components/photosheet-button.hbs"
    }
  });

  _exports.default = _default;
});