define("cco-seek/services/marker-clusterer", ["exports", "ember-data", "cco-seek/utils/map/feature-types"], function (_exports, _emberData, _featureTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var FeatureType = (0, _featureTypes.getFeatureType)();
  var Feature = window.globals.utils.map.Feature;
  var formatLatLng = window.globals.utils.geoHelpers.formatLatLng;
  var getLeaflet = window.globals.utils.map.getLeaflet;

  var _default = Ember.Service.extend({
    // Display cluster records will not be destroyed until after
    // current run cycle. Any code that needs records to be unloaded
    // should therefore be called within Ember.run.next(() => ...
    // after this method has been called.
    unloadAll: function unloadAll() {
      this.store.unloadAll('cluster');
    },
    getClusterFeatures: function getClusterFeatures(displayParams, bounds) {
      var _this = this;

      var isDesktop = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
      var queryParams = displayParams.getProperties('geo_id', 'product_ids', 'digital', 'numbers', 'tab_ids');

      var _bounds = getLeaflet().latLngBounds(bounds);

      queryParams.ne = formatLatLng(_bounds.getNorthEast());
      queryParams.sw = formatLatLng(_bounds.getSouthWest());
      queryParams.size = isDesktop ? 'small' : 'big';
      return this.store.query('cluster', queryParams).then(function (clusters) {
        return clusters.map(function (cluster) {
          var geometry = {
            type: 'Point',
            coordinates: [Number(cluster.get('center.longitude')), Number(cluster.get('center.latitude'))]
          };
          var type = cluster.get('count') === 1 ? FeatureType.DISPLAY : FeatureType.CLUSTER;
          var properties = {
            type: type,
            count: cluster.get('count')
          };

          if (type === FeatureType.DISPLAY) {
            var displayId = cluster.get('ids')[0];
            properties.display = _emberData.default.PromiseObject.create({
              promise: _this.store.find('display', displayId)
            });
          } else {
            properties.displayIds = cluster.get('ids');
          }

          return new Feature(type, geometry, properties);
        });
      });
    }
  });

  _exports.default = _default;
});