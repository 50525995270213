define("cco-seek/utils/map/feature-types/cluster-fan-type", ["exports", "cco-seek/utils/map/cluster-icon", "cco-seek/utils/map/display-tooltip"], function (_exports, _clusterIcon, _displayTooltip) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getLayer = _exports.typeName = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var getLeaflet = window.globals.utils.map.getLeaflet;
  var DIAMETER = 100;
  var RADIUS = DIAMETER / 2;
  var typeName = 'CLUSTER_FAN';
  _exports.typeName = typeName;

  var getLayer = function getLayer(f) {
    return getLeaflet().geoJson(f.getGeoJson(), {
      pointToLayer: pointToLayer
    });
  };

  _exports.getLayer = getLayer;

  function pointToLayer(data, latLng) {
    var icon = getLeaflet().divIcon({
      className: 'cluster-fan'
    });
    var marker = getLeaflet().marker(latLng, {
      icon: icon
    });
    var displays = data.properties.displays;
    marker.on('add', function (e) {
      var icon = e.target._icon;

      if (displays) {
        var displayDivs = displays.map(function (b) {
          return getDisplayDiv(marker, b);
        });
        displayDivs.forEach(function (div, index) {
          var _getDisplayPos = getDisplayPos(index, displayDivs.length),
              _getDisplayPos2 = _slicedToArray(_getDisplayPos, 2),
              x = _getDisplayPos2[0],
              y = _getDisplayPos2[1];

          div.style.left = "".concat(x, "px");
          div.style.top = "".concat(y, "px");
          icon.appendChild(div);
        });
      }

      icon.appendChild(getClusterIconImg(data.properties.count));
    });
    return marker;
  }

  function getClusterIconImg(count) {
    var clusterIcon = document.createElement('img');
    clusterIcon.setAttribute('src', (0, _clusterIcon.getClusterIconUri)(count));
    clusterIcon.classList.add('cluster-icon');
    return clusterIcon;
  }

  function getDisplayDiv(marker, display) {
    var div = document.createElement('div');
    div.classList.add('display-icon');
    div.innerHTML = (0, _displayTooltip.default)(display.get('number'));
    var facing = display.get('facing');

    if (facing) {
      div.classList.add("facing-".concat(facing.toLowerCase()));
    }

    div.onclick = function (e) {
      marker.fire('click', _objectSpread(_objectSpread({}, e), {}, {
        displayId: display.id
      }));
      e.stopPropagation();
    };

    return div;
  } // Get position [ x, y ] of display icon relative to parent:


  function getDisplayPos(index, total) {
    var theta = (index + 1) * (2 * Math.PI / total) - Math.PI;
    return [(RADIUS - 15) * Math.cos(theta) + RADIUS - 10, (RADIUS - 15) * Math.sin(theta) + RADIUS - 10];
  }
});