define("cco-seek/components/radio-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'input',
    attributeBindings: ['type', 'name', 'selected'],
    type: 'radio',
    selectedDidChange: function () {
      var el = this.$();

      if (el) {
        this.$().prop('checked', this.get('selected'));
      }
    }.observes('selected'),
    didInsertElement: function didInsertElement() {
      this.$().prop('checked', this.get('selected'));
    },
    change: function change() {
      var checked = this.$().prop('checked');
      this.set('selected', checked);

      if (checked) {
        Ember.$("input[type=radio][name=".concat(this.get('name'), "]")).not(this.$()).prop('checked', false).change();
      }
    }
  });

  _exports.default = _default;
});