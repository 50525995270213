define("cco-seek/helpers/pluralize-word", ["exports", "ember-inflector"], function (_exports, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.pluralizeWord = pluralizeWord;
  _exports.default = void 0;

  function pluralizeWord(value, _ref) {
    var count = _ref.count;

    if (count === 1) {
      return _emberInflector.default.inflector.singularize(value.toString());
    } else {
      return _emberInflector.default.inflector.pluralize(value.toString());
    }
  }

  var _default = Ember.Helper.helper(pluralizeWord);

  _exports.default = _default;
});