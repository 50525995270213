define("cco-seek/serializers/display", ["exports", "cco-seek/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    modelNameFromPayloadKey: function modelNameFromPayloadKey(payloadKey) {
      return this._super(payloadKey, 'display');
    },
    keyForAttribute: function keyForAttribute(attr) {
      if (attr === 'weeklyImpressions18Plus') {
        return 'weekly_impressions_18_plus';
      } else {
        return this._super(attr);
      }
    }
  });

  _exports.default = _default;
});