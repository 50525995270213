define("cco-seek/templates/results/search-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sgNJXdPI",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"search-bar\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"menu-button\"],[7],[0,\"\\n    \"],[6,\"button\"],[3,\"action\",[[19,0,[]],\"toggleMenu\"]],[7],[0,\"\\n        \"],[6,\"span\"],[9,\"class\",\"cco-icon-hamburger\"],[7],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"controls-container\"],[7],[0,\"\\n    \"],[1,[25,\"geo-autocomplete\",null,[[\"inputVal\",\"options\",\"selected\",\"maxSuggestions\",\"isLocationSearch\",\"location\",\"onLocateError\"],[[20,[\"searchValue\"]],[20,[\"options\"]],[20,[\"selectedGeo\"]],4,[20,[\"model\",\"isLocationSearch\"]],[20,[\"location\"]],\"onLocateError\"]]],false],[0,\"\\n\"],[4,\"link-to\",[\"my-displays\"],[[\"class\"],[\"btn my-displays\"]],{\"statements\":[[4,\"if\",[[20,[\"cartService\",\"numDisplays\"]]],null,{\"statements\":[[0,\"        \"],[6,\"div\"],[9,\"class\",\"my-displays-count\"],[7],[1,[20,[\"cartService\",\"numDisplays\"]],false],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[6,\"div\"],[10,\"class\",[26,[\"text \",[25,\"unless\",[[20,[\"cartService\",\"numDisplays\"]],\"vert-center\"],null]]]],[7],[0,\"My Displays\"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[1,[25,\"filters-drawer\",null,[[\"model\",\"apply\"],[[20,[\"model\"]],\"updateFilterParams\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cco-seek/templates/results/search-bar.hbs"
    }
  });

  _exports.default = _default;
});