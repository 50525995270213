define("cco-seek/controllers/landing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    applicationCtrl: Ember.inject.controller('application'),
    selectedGeoChanged: function () {
      if (this.get('selectedGeo')) {
        this.set('location', null);
      }
    }.observes('selectedGeo'),
    currentLocationChanged: function () {
      if (this.get('location')) {
        this.set('selectedGeo', null);
      }
    }.observes('location'),
    isLoadingGeos: function () {
      return this.get('options') && !this.get('options.isFulfilled');
    }.property('options.isFulfilled'),
    retrieveOptions: function () {
      if (this.get('searchValue.length') > 2) {
        var options = this.store.query('geo', {
          q: this.get('searchValue'),
          per_page: 4
        });
        this.set('options', options);
      }
    }.observes('searchValue'),
    actions: {
      search: function search(_ref) {
        var geo = _ref.geo,
            location = _ref.location;

        if (location) {
          this.transitionToRoute('results', {
            queryParams: {
              latitude: location.latitude,
              longitude: location.longitude
            }
          });
        } else if (geo) {
          this.transitionToRoute('results', {
            queryParams: {
              geo_id: geo.get('id')
            }
          });
        } else {
          console.error('Must select a geo or location in order to do ' + 'a search');
        }
      }
    }
  });

  _exports.default = _default;
});