define("cco-seek/controllers/my-displays", ["exports", "cco-seek/utils/map/feature-types", "cco-seek/constants"], function (_exports, _featureTypes, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var FeatureType = (0, _featureTypes.getFeatureType)();
  var Feature = window.globals.utils.map.Feature;

  var _default = Ember.Controller.extend({
    cartService: Ember.inject.service('cart-service'),
    markerFeatures: Ember.A([]),
    mapOpts: {
      bounds: _constants.USA_BOUNDS
    },
    updateDisplayMarkers: Ember.observer('model.[]', function () {
      this.removeAllMarkers();
      var displays = this.get('model');

      if (displays.get('length')) {
        var markerFeatures = displays.map(function (display) {
          var geometry = {
            type: 'Point',
            coordinates: [Number(display.get('geolocation.longitude')), Number(display.get('geolocation.latitude'))]
          };
          var properties = {
            display: display
          };
          return new Feature(FeatureType.DISPLAY, geometry, properties);
        });
        this.set('markerFeatures', markerFeatures);
      } else {
        this.set('markerFeatures', null);
        this.set('mapOpts.bounds', _constants.USA_BOUNDS);
      }
    }),
    didChangeDisplays: Ember.observer('model.[]', function () {
      Ember.run.once(this, 'updateDisplayMarkers');
    }),
    removeAllMarkers: function removeAllMarkers() {
      this.set('markerFeatures', Ember.A([]));
    },
    joinedTabIds: Ember.computed('model.[]', function () {
      var displays = this.get('model');
      return displays.map(function (display) {
        return display.get('tabId');
      }).compact().join(', ');
    }),
    allPhotosheetsUrl: Ember.computed('model.[]', function () {
      var displays = this.get('model');
      var joinedDisplayIds = displays.map(function (display) {
        return display.get('id');
      }).compact().join(',');
      return "".concat(EmberENV.getHost, "/pdf/photosheet?displays=").concat(joinedDisplayIds);
    }),
    actions: {
      onRemoveDisplay: function onRemoveDisplay(display) {
        this.get('model').removeObject(display);
      },
      removeAll: function removeAll() {
        var _this = this;

        this.get('cartService').removeAllDisplays().then(function () {
          _this.set('model', Ember.A([]));
        }, function (err) {
          console.error("Failed to remove all displays from cart: ".concat(err));
        });
      }
    }
  });

  _exports.default = _default;
});