define("cco-seek/utils/keymap", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    UP: 38,
    DOWN: 40,
    ENTER: 13
  };
  _exports.default = _default;
});