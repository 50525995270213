define("cco-seek/utils/map/feature-types/display-tooltip-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getLayer = _exports.typeName = void 0;
  var getLeaflet = window.globals.utils.map.getLeaflet;
  var typeName = 'DISPLAY_TOOLTIP';
  _exports.typeName = typeName;

  var getLayer = function getLayer(f) {
    return getLeaflet().geoJson(f.getGeoJson(), {
      pointToLayer: pointToLayer
    });
  };

  _exports.getLayer = getLayer;

  function pointToLayer(data, latLng) {
    var Tooltip = getLeaflet().Class.extend({
      initialize: function initialize(latlng, data) {
        this._latlng = latlng;
        this._data = data;
      },
      onAdd: function onAdd(map) {
        this._map = map;
        this._el = getLeaflet().DomUtil.create('div', 'display-tooltip leaflet-zoom-hide');
        this._el.innerHTML = this._data.properties.title;
        map.getPanes().popupPane.appendChild(this._el);
        map.on('viewreset', this._reset, this);

        this._reset();
      },
      onRemove: function onRemove(map) {
        map.getPanes().popupPane.removeChild(this._el);
        map.off('viewreset', this._reset, this);
      },
      _reset: function _reset() {
        var pos = this._map.latLngToLayerPoint(this._latlng);

        getLeaflet().DomUtil.setPosition(this._el, pos);
      }
    });
    return new Tooltip(latLng, data);
  }
});