define("cco-seek/routes/results/index", ["exports", "cco-seek/constants", "cco-seek/utils/display-type"], function (_exports, _constants, _displayType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    searchBounds: null,
    queryParams: {
      geo_id: {
        refreshModel: true
      },
      product_ids: {
        refreshModel: true
      },
      digital: {
        refreshModel: true
      },
      tab_ids: {
        refreshModel: true
      },
      numbers: {
        refreshModel: true
      }
    },
    setupController: function setupController() {
      // If media is desktop we can transition directly to show the map. The
      // only exception is when entering the results.photosheet route, which
      // should be handled from the photosheet route by calling the
      // 'preventMapTransition' action on this route.
      if (this.get('media').isDesktop && !this.get('preventMapTransition')) {
        this.transitionTo('results.index.map', {
          isSticky: true
        });
      }
    },
    renderTemplate: function renderTemplate(_controller, model) {
      this.render('results.index');
      this.render('results.searchBar', {
        into: 'results.index',
        outlet: 'nav',
        model: model
      });
      this.render('results.displays.index', {
        into: 'results.index',
        outlet: 'list',
        model: model
      });
      this.render('results.map', {
        into: 'results.index',
        outlet: 'content',
        model: model
      });
    },
    model: function model(_ref) {
      var geo_id = _ref.geo_id,
          latitude = _ref.latitude,
          longitude = _ref.longitude,
          product_ids = _ref.product_ids,
          tab_ids = _ref.tab_ids,
          numbers = _ref.numbers,
          digital = _ref.digital,
          per_page = _ref.per_page,
          page = _ref.page;
      var isLocationSearch = latitude && longitude;
      var radius = _constants.DEFAULT_SEARCH_RADIUS;
      var displayParams = isLocationSearch ? {
        latitude: latitude,
        longitude: longitude,
        radius: radius
      } : {
        geo_id: geo_id
      };
      displayParams.per_page = per_page || 50;
      displayParams.page = page || 1;
      displayParams.product_ids = product_ids;
      displayParams.tab_ids = tab_ids;
      displayParams.numbers = numbers;
      displayParams.digital = digital;
      var bounds = this.get('searchBounds');

      if (!geo_id && bounds) {
        displayParams.ne = bounds.ne;
        displayParams.sw = bounds.sw;
      }

      this.set('displayParams', displayParams);
      var model = Ember.Object.create({
        isLocationSearch: !!isLocationSearch,
        displayType: _displayType.default.fromDigitalParam(digital),
        displays: Ember.A([]),
        displaysPromise: this.store.query('display', displayParams),
        displayParams: Ember.Object.create(displayParams),
        products: Ember.A([]),
        productsPromise: this.store.query('product', {})
      });
      model.displaysPromise.then(function (displays) {
        model.set('displays', displays.toArray());
        model.set('pagination', displays.get('meta.pagination'));
      });
      model.productsPromise.then(function (products) {
        model.set('products', products.filter(function (product) {
          return product.get('category') !== 'Contract Option';
        }));
      });

      if (geo_id && !isLocationSearch) {
        model.reopen({
          geo: null,
          geoPromise: this.store.findRecord('geo', geo_id, {
            reload: true
          })
        });
        model.geoPromise.then(function (geo) {
          model.set('geo', geo);
        });
      }

      if (isLocationSearch) {
        model.set('latitude', latitude);
        model.set('longitude', longitude);
      }

      var indexCtrl = this.controllerFor('results.index');
      indexCtrl.send('showList');
      this.set('currentModel', model);
      return model;
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        var mapsCtrl = this.controllerFor('results.map');
        mapsCtrl.set('geoBoundariesFeature', null);
        mapsCtrl.set('circleFeature', null);
      }

      var isLocationSearch = controller.get('latitude') && controller.get('longitude');

      if (isLocationSearch) {
        controller.set('geo_id', '');
      } else {
        controller.set('latitude', '');
        controller.set('longitude', '');
      }
    },
    actions: {
      updateGeo: function updateGeo(geoId) {
        this.transitionTo({
          queryParams: {
            geo_id: geoId,
            latitude: null,
            longitude: null
          },
          isSticky: true
        });
      },
      clearGeo: function clearGeo() {
        this.transitionTo({
          queryParams: {
            geo_id: null
          },
          isSticky: true
        });
      },
      updateLocation: function updateLocation(location) {
        this.transitionTo({
          queryParams: {
            latitude: location.latitude,
            longitude: location.longitude,
            geo_id: null
          },
          isSticky: true
        });
        this.set('displayParams.latitude', location.latitude);
        this.set('displayParams.longitude', location.longitude);
        var mapCtrl = this.controllerFor('results.map');
        mapCtrl.set('model.latitude', location.latitude);
        mapCtrl.set('model.longitude', location.longitude);
      },
      removeSearchBar: function removeSearchBar() {
        this.disconnectOutlet({
          outlet: 'nav',
          parentView: 'results.index'
        });
      },
      renderSearchBar: function renderSearchBar() {
        this.render('results.searchBar', {
          into: 'results.index',
          outlet: 'nav'
        });
      },
      showPhotosheet: function showPhotosheet(displayId) {
        this.transitionTo('results.index.photosheet', displayId, {
          isSticky: true
        });
      },
      preventMapTransition: function preventMapTransition() {
        this.set('preventMapTransition', true);
      },
      updateFilterParams: function updateFilterParams(_ref2) {
        var products = _ref2.products,
            displayType = _ref2.displayType,
            tabIds = _ref2.tabIds,
            displayNums = _ref2.displayNums;

        var digitalParam = _displayType.default.toDigitalParam(displayType);

        var params = {};

        if (products && products.get('length')) {
          params.product_ids = products.mapBy('id');
        } else {
          params.product_ids = '';
        }

        params.digital = digitalParam;
        params.tab_ids = tabIds;
        params.numbers = displayNums;
        this.transitionTo({
          queryParams: params,
          isSticky: true
        });
      },
      updateSearchBounds: function updateSearchBounds(bounds) {
        var currentBounds = this.get('searchBounds');

        if (!currentBounds || currentBounds.ne !== bounds.ne || currentBounds.sw !== bounds.sw) {
          this.set('searchBounds', bounds);
          var displayParams = this.get('displayParams');
          displayParams.ne = bounds.ne;
          displayParams.sw = bounds.sw;

          if (displayParams.latitude && displayParams.longitude && displayParams.radius) {
            delete displayParams.latitude;
            delete displayParams.longitude;
            delete displayParams.radius;
          }

          this.set('displayParams', displayParams);
          var displaysCtrl = this.controllerFor('results.displays.index');
          var displaysPromise = this.store.query('display', displayParams);
          displaysCtrl.set('model.displaysPromise', displaysPromise);
          displaysPromise.then(function (displays) {
            displaysCtrl.set('model.displays', displays.toArray());
            displaysCtrl.set('model.pagination', displays.get('meta.pagination'));
          });
        }
      },
      loadMoreDisplays: function loadMoreDisplays() {
        var _this = this;

        var displayParams = this.get('displayParams');
        var displaysCtrl = this.controllerFor('results.displays.index');

        var _this$get = this.get('currentModel.pagination'),
            pages = _this$get.pages,
            page = _this$get.page;

        if (page < pages) {
          displaysCtrl.set('isLoadingMore', true);
          displayParams.page = page + 1;
          this.store.query('display', displayParams).then(function (displays) {
            var newDataArr = displays.toArray();

            _this.get('currentModel.displays').pushObjects(newDataArr);

            _this.set('currentModel.pagination', displays.get('meta.pagination'));

            _this.set('displayParams', displayParams);

            displaysCtrl.get('isLoadingMore', false);
          });
        }
      }
    }
  });

  _exports.default = _default;
});