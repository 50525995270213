define("cco-seek/mixins/filter-blank-params-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    _hydrateUnsuppliedQueryParams: function _hydrateUnsuppliedQueryParams(state, queryParams) {
      this._super.call(this, state, queryParams);

      Object.keys(queryParams).forEach(function (param) {
        if (!queryParams[param]) {
          delete queryParams[param];
        }
      });
    }
  });

  _exports.default = _default;
});