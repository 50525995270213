define("cco-seek/templates/photosheet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xzLxSzhq",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"header\"],[7],[0,\"\\n  \"],[6,\"img\"],[9,\"class\",\"cco-logo\"],[9,\"src\",\"/assets/images/cco-logo-white-600.png\"],[7],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[1,[25,\"display-detail\",null,[[\"model\",\"classNames\"],[[20,[\"model\"]],\"photosheet standalone\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cco-seek/templates/photosheet.hbs"
    }
  });

  _exports.default = _default;
});