define("cco-seek/components/bg-image-slideshow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TIME_PER_IMAGE_SECONDS = 10;

  var _default = Ember.Component.extend({
    classNames: ['bg-image-slideshow'],
    didInsertElement: function didInsertElement() {
      var imageDivs = this.$().children();
      var numImages = imageDivs.length;
      var activeIndex = 0;
      imageDivs.css('opacity', 0);
      imageDivs.eq(activeIndex).css('opacity', 1);
      setInterval(function () {
        activeIndex = (activeIndex + 1) % numImages;
        imageDivs.css('opacity', 0);
        imageDivs.eq(activeIndex).css('opacity', 1);
      }, TIME_PER_IMAGE_SECONDS * 1000);
    }
  });

  _exports.default = _default;
});