define("cco-seek/utils/images", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.DISPLAY_PLACEHOLDER_THUMB = _exports.DISPLAY_PLACEHOLDER = _exports.MARKER_SPRITE_SHEET = void 0;
  var MARKER_SPRITE_SHEET = {
    url: 'assets/images/marker-sprite-sheet.png',
    meta: {
      iconSize: {
        width: 32,
        height: 32
      },
      anchor: {
        x: 16,
        y: 28
      }
    }
  };
  _exports.MARKER_SPRITE_SHEET = MARKER_SPRITE_SHEET;
  var DISPLAY_PLACEHOLDER = {
    url: '/assets/images/placeholder.png'
  };
  _exports.DISPLAY_PLACEHOLDER = DISPLAY_PLACEHOLDER;
  var DISPLAY_PLACEHOLDER_THUMB = {
    url: '/assets/images/placeholder-thumb.png'
  };
  _exports.DISPLAY_PLACEHOLDER_THUMB = DISPLAY_PLACEHOLDER_THUMB;
});