define("cco-seek/components/photosheet-button", ["exports", "npm:uri-js"], function (_exports, _npmUriJs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    label: 'Photosheet',
    includeUnique: false,
    classNames: ['photosheet-btn'],
    uniquePhotoSheetUrl: Ember.computed('photoSheetUrl', function () {
      return this.photoSheetUrlWithParams('uniqueSpecSheets=1');
    }),
    photoSheetOnlyUrl: Ember.computed('photoSheetUrl', function () {
      return this.photoSheetUrlWithParams('specsheet=0');
    }),
    specSheetOnlyUrl: Ember.computed('photoSheetUrl', function () {
      return this.photoSheetUrlWithParams('photosheet=0');
    }),
    uniqueSpecSheetOnlyUrl: Ember.computed('photoSheetUrl', function () {
      return this.photoSheetUrlWithParams('photosheet=0', 'uniqueSpecSheets=1');
    }),
    photoSheetUrlWithParams: function photoSheetUrlWithParams() {
      var url = this.get('photoSheetUrl');

      if (url) {
        var uri = _npmUriJs.default.parse(url); // Filter out any params that are blank/missing and then join with '&':


        for (var _len = arguments.length, params = new Array(_len), _key = 0; _key < _len; _key++) {
          params[_key] = arguments[_key];
        }

        uri.query = [uri.query].concat(params).filter(function (query) {
          return query;
        }).join('&');
        return _npmUriJs.default.serialize(uri);
      }

      return null;
    }
  });

  _exports.default = _default;
});