define("cco-seek/components/geo-locator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isRetrieving: false,
    geolocation: Ember.inject.service('geolocation'),
    didInsertElement: function didInsertElement() {
      var _this = this;

      this.$('.current-location-link').mouseover(function () {
        _this.set('isMouseOver', true);
      });
      this.$('.current-location-link').mouseleave(function () {
        _this.set('isMouseOver', false);
      });
    },
    actions: {
      getCurrentLocation: function getCurrentLocation() {
        var _this2 = this;

        this.set('isRetrieving', true);
        this.sendAction('start');
        var currentLocation = this.get('geolocation.currentLocation');
        currentLocation().then(function (location) {
          _this2.sendAction('complete', location.coords);
        }).catch(function (err) {
          _this2.sendAction('error', err);
        }).finally(function () {
          _this2.set('isRetrieving', false);
        });
      }
    }
  });

  _exports.default = _default;
});