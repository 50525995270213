define("cco-seek/controllers/results/search-bar", ["exports", "cco-seek/utils/error-alert"], function (_exports, _errorAlert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    cartService: Ember.inject.service('cart-service'),
    landingController: Ember.inject.controller('landing'),
    resultsController: Ember.inject.controller('results.index'),
    selectedGeo: null,
    init: function init() {
      this._super.apply(this, arguments);

      var selectedGeo = this.get('landingController.selectedGeo');

      if (selectedGeo) {
        this.set('selectedGeo', selectedGeo);
      }
    },
    isLoadingGeos: function () {
      return this.get('options') && !this.get('options.isFulfilled');
    }.property('options.isFulfilled'),
    retrieveOptions: function () {
      if (this.get('searchValue.length') > 2) {
        var options = this.store.query('geo', {
          q: this.get('searchValue'),
          per_page: 4
        });
        this.set('options', options);
      }
    }.observes('searchValue'),
    setLocationFromModel: function () {
      var latitude = this.get('model.latitude');
      var longitude = this.get('model.longitude');

      if (latitude && longitude) {
        this.set('selectedGeo', null);
      }
    }.observes('model.latitude', 'model.longitude'),
    handleUpdateLocation: function () {
      this.set('model.isLocationSearch', true);

      if (this.get('location')) {
        this.set('searchValue', '');
        this.set('landingController.searchValue', '');
        this.set('landingController.location', this.get('location'));
        this.set('landingController.selectedGeo', null);
        this.set('landingController.isLocationSearch', true);
        this.send('updateLocation', this.get('location'));
        var resultsController = this.get('resultsController');
        resultsController.send('clearError');
      }
    }.observes('location'),
    geoChanged: function () {
      var searchValue = this.get('model.geo.name') || '';
      var selectedGeo = this.get('model.geo');
      this.set('searchValue', searchValue);
      this.set('selectedGeo', selectedGeo);
      this.set('landingController.searchValue', searchValue);
      this.set('landingController.selectedGeo', selectedGeo);
    }.observes('model.geo'),
    handleUpdateGeo: function () {
      var _this = this;

      Ember.run.once(function () {
        var modelGeoId = _this.get('model.geo.id');

        var selectedGeoId = _this.get('selectedGeo.id');

        if (selectedGeoId && selectedGeoId !== modelGeoId) {
          _this.set('location', null);

          _this.set('landingController.location', null);

          _this.set('landingController.selectedGeo', _this.get('selectedGeo'));

          _this.set('landingController.isLocationSearch', false);

          _this.send('updateGeo', _this.get('selectedGeo.id'));

          var resultsController = _this.get('resultsController');

          resultsController.send('clearError');
        }
      });
    }.observes('selectedGeo'),
    actions: {
      onLocateError: function onLocateError() {
        this.set('model.isLocationSearch', false);
        var error = new _errorAlert.default("We can't seem to locate you...", 'Please be sure location services are enabled on your device ' + 'and allow the app to access your current location.');
        var resultsController = this.get('resultsController');
        resultsController.send('showError', error);
      }
    }
  });

  _exports.default = _default;
});