define("cco-seek/transitions/slide-out-up", ["exports", "cco-seek/transitions/slide-over"], function (_exports, _slideOver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = slideOutUp;

  function slideOutUp(opts) {
    return _slideOver.default.call(this, 'y', -1, false, opts);
  }
});