define("cco-seek/helpers/to-upper-case", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.toUpperCase = toUpperCase;
  _exports.default = void 0;

  function toUpperCase(value) {
    if (typeof value === 'string') {
      return value.toUpperCase();
    } else {
      return value;
    }
  }

  var _default = Ember.Helper.helper(toUpperCase);

  _exports.default = _default;
});