define("cco-seek/serializers/application", ["exports", "active-model-adapter"], function (_exports, _activeModelAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _activeModelAdapter.ActiveModelSerializer.extend({
    modelNameFromPayloadKey: function modelNameFromPayloadKey(payloadKey, modelName) {
      if (modelName && payloadKey === 'data') {
        return this._super(modelName);
      } else {
        return this._super(payloadKey);
      }
    },
    normalizeFindAllResponse: function normalizeFindAllResponse(_store, _primaryModelClass, payload) {
      if (payload.included) delete payload.included;

      if (payload.errors && payload.errors.length > 0) {
        delete payload.data;
      } else {
        delete payload.errors;
      }

      return this._super.apply(this, arguments);
    },
    normalizeResponse: function normalizeResponse(_store, _primaryModelClass, payload) {
      if (payload.included) delete payload.included;

      if (payload.errors && payload.errors.length > 0) {
        delete payload.data;
      } else {
        delete payload.errors;
      }

      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});