define("cco-seek/services/geolocation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    currentLocation: function currentLocation() {
      return new Ember.RSVP.Promise(function (resolve, reject) {
        if (navigator.geolocation) {
          if (EmberENV.spoofLocation) {
            Ember.run.later(function () {
              console.info('Returning spoof location:', EmberENV.spoofLocation);
              resolve(EmberENV.spoofLocation);
            }, 2000);
          } else {
            navigator.geolocation.getCurrentPosition(resolve, reject);
          }
        } else {
          reject('Geolocation not supported by browser.');
        }
      });
    }
  });

  _exports.default = _default;
});