define("cco-seek/utils/map/feature-types/current-location-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getLayer = _exports.typeName = void 0;
  var getLeaflet = window.globals.utils.map.getLeaflet;
  var typeName = 'CURRENT_LOCATION';
  _exports.typeName = typeName;

  function pointToLayer(_, latLng) {
    return getLeaflet().marker(latLng);
  }

  var getLayer = function getLayer(f) {
    return getLeaflet().geoJson(f.getGeoJson(), {
      pointToLayer: pointToLayer
    });
  };

  _exports.getLayer = getLayer;
});