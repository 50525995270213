define("cco-seek/utils/map/cluster-icon", ["exports", "cco-seek/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getSize = getSize;
  _exports.getClusterIconUri = getClusterIconUri;
  var countDigits = window.globals.utils.countDigits;

  function getSize(count) {
    var numDigits = countDigits(count);
    var size = 28;

    if (numDigits > 2) {
      size += 6 * (numDigits - 2);
    }

    return size;
  }

  function getClusterIconUri(count) {
    var size = getSize(count);
    var stroke = 2;
    var half = size / 2;
    var canvas = document.createElement('canvas');
    canvas.setAttribute('width', size);
    canvas.setAttribute('height', size);
    var context = canvas.getContext('2d');
    context.lineWidth = stroke;
    context.strokeStyle = _constants.Color.CCO_BRIGHT_AQUA;
    context.fillStyle = _constants.Color.CCO_BRIGHT_AQUA;
    context.beginPath();
    context.arc(half, half, half - stroke, 0 * Math.PI, 2 * Math.PI, false);
    context.stroke();
    context.fill();
    context.fillStyle = _constants.Color.WHITE;
    context.font = '12px verdana';
    context.textBaseline = 'middle';
    var text = context.measureText(count);
    context.fillText(count, half - text.width / 2, half);
    return canvas.toDataURL();
  }
});