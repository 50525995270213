define("cco-seek/templates/results/index/photosheet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4iNqdG+f",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"display-detail\",null,[[\"model\",\"classNames\",\"back\"],[[20,[\"model\"]],\"photosheet\",\"back\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cco-seek/templates/results/index/photosheet.hbs"
    }
  });

  _exports.default = _default;
});