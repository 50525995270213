define("cco-seek/services/copy-to-clipboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    /*
     * Copies the value of the content variable to the clipboard.
     * If the browser is Internet Explorer then this is very simple.
     *
     * Other browsers (excluding Firefox) provide it on the event. In order to get
     * around this we need to perform a few steps:
     *
     * Note: If Firefox is the browser - i.e. executing the copy command failed -
     * we display a message to the user telling them to copy normally.
     */
    copyToClipboard: function copyToClipboard(content) {
      var selection;
      var success = true;
      var range = document.createRange();
      var tmpEl = document.createElement('div'); // Internet Explorer

      if (window.clipboardData) {
        window.clipboardData.setData('Text', content);
        return true;
      }

      tmpEl.setAttribute('style', 'visibility:"hidden";');
      tmpEl.textContent = content;
      document.body.appendChild(tmpEl);
      range.selectNodeContents(tmpEl.firstChild); // TEXT_NODE

      selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);

      try {
        success = document.execCommand('copy', false, null);
      } catch (e) {
        success = false;
      }

      document.body.removeChild(tmpEl);
      return success;
    }
  });

  _exports.default = _default;
});