define("cco-seek/components/displays-filter", ["exports", "cco-seek/utils/parse-delimited-list", "cco-seek/utils/displays-filter-mode"], function (_exports, _parseDelimitedList, _displaysFilterMode) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['displays-filter'],
    placeholder: 'Provide a comma separated list of numbers',
    value: '',
    mode: _displaysFilterMode.default.TAB_IDS,
    tabIds: Ember.A([]),
    displayNums: Ember.A([]),
    isDisplayNumsMode: Ember.computed.equal('mode', _displaysFilterMode.default.DISPLAY_NUMS),
    isTabIdsMode: Ember.computed.equal('mode', _displaysFilterMode.default.TAB_IDS),
    init: function init() {
      this._super.apply(this, arguments);

      if (this.get('tabIds.length') && !this.get('displayNums.length')) {
        this.set('mode', _displaysFilterMode.default.TAB_IDS);
        this.set('value', this.get('tabIds').join(', '));
      } else if (this.get('displayNums.length') && !this.get('tabIds.length')) {
        this.set('mode', _displaysFilterMode.default.DISPLAY_NUMS);
        this.set('value', this.get('displayNums').join(', '));
      } else if (this.get('displayNums.length') && this.get('tabIds.length')) {
        console.warn('Cannot set both URL query parameters "numbers" & "tab_ids". Ignoring.');
        this.set('mode', _displaysFilterMode.default.TAB_IDS);
        this.set('tabIds', Ember.A([]));
        this.set('displayNums', Ember.A([]));
      }
    },
    valueDidChange: Ember.observer('value', function () {
      var mode = this.get('mode');
      var value = this.get('value');

      switch (mode) {
        case _displaysFilterMode.default.TAB_IDS:
          this.set('tabIds', (0, _parseDelimitedList.default)(value));
          break;

        case _displaysFilterMode.default.DISPLAY_NUMS:
          this.set('displayNums', (0, _parseDelimitedList.default)(value));
          break;
      }
    }),
    actions: {
      useTabIds: function useTabIds() {
        var value = this.get('value');
        this.set('mode', _displaysFilterMode.default.TAB_IDS);
        this.set('tabIds', (0, _parseDelimitedList.default)(value));
        this.set('displayNums', null);
      },
      useDisplayNums: function useDisplayNums() {
        var value = this.get('value');
        this.set('mode', _displaysFilterMode.default.DISPLAY_NUMS);
        this.set('displayNums', (0, _parseDelimitedList.default)(value));
        this.set('tabIds', null);
      }
    }
  });

  _exports.default = _default;
});