define("cco-seek/routes/results/index/map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController: function setupController() {
      this.controllerFor('results.index').send('showContent');
    },
    resetController: function resetController(_, isExiting) {
      if (isExiting) {
        this.controllerFor('results.index').send('showList');
      }
    },
    model: function model(_ref) {
      var geo_id = _ref.geo_id,
          latitude = _ref.latitude,
          longitude = _ref.longitude,
          radius = _ref.radius;
      var isLocationSearch = latitude && longitude;
      var displayParams = isLocationSearch ? {
        latitude: latitude,
        longitude: longitude,
        radius: radius
      } : {
        geo_id: geo_id
      };
      var model = Ember.Object.create({
        displays: Ember.A([]),
        displaysPromise: this.store.query('display', displayParams),
        geos: this.store.findAll('geo')
      });
      model.displaysPromise.then(function (displays) {
        model.set('displays', displays);
      });

      if (geo_id && !isLocationSearch) {
        model.reopen({
          geo: null,
          geoPromise: this.store.find('geo', geo_id, {
            reload: true
          })
        });
        model.geoPromise.then(function (geo) {
          model.set('geo', geo);
        });
      }

      if (isLocationSearch) {
        model.set('latitude', latitude);
        model.set('longitude', longitude);
        model.set('radius', radius || 10);
      }

      return model;
    }
  });

  _exports.default = _default;
});