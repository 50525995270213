define("cco-seek/templates/menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+CGc17Z3",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"menu\"],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"logo-container\"],[7],[0,\"\\n    \"],[6,\"img\"],[9,\"class\",\"cco-logo\"],[9,\"src\",\"/assets/images/cco-logo-white-300.png\"],[7],[8],[0,\"\\n  \"],[8],[0,\"\\n  \"],[6,\"ul\"],[3,\"action\",[[19,0,[]],\"hideMenu\"]],[7],[0,\"\\n    \"],[6,\"li\"],[7],[0,\"\\n      \"],[4,\"link-to\",[\"index\"],null,{\"statements\":[[0,\"New Search\"]],\"parameters\":[]},null],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"li\"],[7],[0,\"\\n      \"],[4,\"link-to\",[\"results.index.map\"],null,{\"statements\":[[0,\"Map\"]],\"parameters\":[]},null],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"li\"],[7],[0,\"\\n      \"],[4,\"link-to-strict\",null,[[\"route\"],[\"results.index.index\"]],{\"statements\":[[0,\"List\"]],\"parameters\":[]},null],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"li\"],[7],[0,\"\\n\"],[4,\"link-to\",[\"my-displays\"],null,{\"statements\":[[0,\"        My Displays\\n\"],[4,\"if\",[[20,[\"cartService\",\"numDisplays\"]]],null,{\"statements\":[[0,\"          \"],[6,\"div\"],[9,\"class\",\"my-displays-count\"],[7],[1,[20,[\"cartService\",\"numDisplays\"]],false],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"    \"],[8],[0,\"\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cco-seek/templates/menu.hbs"
    }
  });

  _exports.default = _default;
});