define("cco-seek/utils/map/feature-types/index", ["exports", "cco-seek/utils/map/feature-types/display-type", "cco-seek/utils/map/feature-types/display-tooltip-type", "cco-seek/utils/map/feature-types/cluster-type", "cco-seek/utils/map/feature-types/cluster-fan-type", "cco-seek/utils/map/feature-types/geo-boundary-type", "cco-seek/utils/map/feature-types/current-location-type"], function (_exports, display, displayTooltip, cluster, clusterFan, geoBoundary, currentLocation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.setTypes = _exports.getFeatureType = void 0;
  var _window$globals$utils = window.globals.utils.map,
      getFeatureType = _window$globals$utils.getFeatureType,
      setTypes = _window$globals$utils.setTypes;
  _exports.setTypes = setTypes;
  _exports.getFeatureType = getFeatureType;
  setTypes([display, displayTooltip, cluster, clusterFan, geoBoundary, currentLocation]);
});