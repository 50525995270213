define("cco-seek/templates/components/displays-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NIswGdSj",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"toggle-btns\"],[7],[0,\"\\n  \"],[6,\"button\"],[10,\"class\",[26,[\"btn tab-ids \",[25,\"if\",[[20,[\"isTabIdsMode\"]],\"active\"],null]]]],[3,\"action\",[[19,0,[]],\"useTabIds\"]],[7],[0,\"\\n    Geopath IDs\\n  \"],[8],[0,\"\\n  \"],[6,\"button\"],[10,\"class\",[26,[\"btn display-nums \",[25,\"if\",[[20,[\"isDisplayNumsMode\"]],\"active\"],null]]]],[3,\"action\",[[19,0,[]],\"useDisplayNums\"]],[7],[0,\"\\n    DISPLAY NUMBERS\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"input-container\"],[7],[0,\"\\n  \"],[1,[25,\"textarea\",null,[[\"placeholder\",\"value\"],[[20,[\"placeholder\"]],[20,[\"value\"]]]]],false],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "cco-seek/templates/components/displays-filter.hbs"
    }
  });

  _exports.default = _default;
});