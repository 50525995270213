define("cco-seek/routes/results/index/photosheet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel: function beforeModel(transition) {
      transition.send('preventMapTransition');
    },
    model: function model(_ref) {
      var id = _ref.id;
      return this.store.find('display', id);
    },
    renderTemplate: function renderTemplate() {
      this.render({
        into: 'results.index',
        outlet: 'photosheet'
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      this.send('removeSearchBar');
    },
    resetController: function resetController(_controller, isExiting) {
      if (isExiting) {
        this.send('renderSearchBar');
      }
    },
    onMediaChanged: function () {
      this.renderTemplate();
    }.observes('media.isMobile', 'media.isTablet', 'media.isDesktop')
  });

  _exports.default = _default;
});