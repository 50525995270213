define("cco-seek/components/display-item", ["exports", "cco-seek/utils/format-number", "cco-seek/utils/images"], function (_exports, _formatNumber, _images) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    cartService: Ember.inject.service('cart-service'),
    didChangeCenter: function () {
      this.set('center', {
        lat: Number(this.get('model.geolocation.latitude')),
        lng: Number(this.get('model.geolocation.longitude'))
      });
    }.observes('model.geolocation.latitude', 'model.geolocation.longitude').on('init'),
    illuminatedText: function () {
      var illuminated = this.get('model.illuminated');
      var illuminatedHours = this.get('model.illuminatedHours');
      return illuminated ? "".concat(illuminatedHours, " hours") : 'No';
    }.property('model.illuminated', 'model.illuminatedHours'),
    geolocationFormatted: function () {
      var latitude = this.get('model.geolocation.latitude');
      var longitude = this.get('model.geolocation.longitude');

      if (latitude && longitude) {
        return "".concat((0, _formatNumber.default)(latitude, 4), ", ").concat((0, _formatNumber.default)(longitude, 4));
      } else {
        return '';
      }
    }.property('model.geolocation'),
    photoThumbUrl: function () {
      return this.get('model.photoThumbUrl') || _images.DISPLAY_PLACEHOLDER_THUMB.url;
    }.property('model.photoThumbUrl'),
    photoUrl: function () {
      return this.get('model.photoUrl') || _images.DISPLAY_PLACEHOLDER.url;
    }.property('model.photoUrl'),
    mapUrl: function () {
      return "".concat(EmberENV.getHost, "/map/display?") + "display_id=".concat(this.get('model.id'), "&") + "key=".concat(EmberENV.mapboxToken || '');
    }.property('model.id'),
    mapUrlLarge: function () {
      return this.get('mapUrl') + '&h=160&w=496';
    }.property('model.id'),
    mapUrlSmall: function () {
      return this.get('mapUrl') + '&h=112&w=176';
    }.property('mapUrl'),
    inCart: function () {
      return this.get('cartService').displayInCart(this.get('model'));
    }.property('cartService.cart.displays.[]', 'model'),
    actions: {
      toggleCart: function toggleCart() {
        if (this.get('inCart')) {
          this.send('removeFromCart');
        } else {
          this.send('addToCart');
        }
      },
      addToCart: function addToCart() {
        var _this = this;

        var display = this.get('model');
        this.get('cartService').addToCart(display).then(function () {
          _this.sendAction('onAdd', display);
        }, function (err) {
          console.error("Failed to add to cart: ".concat(err));
        });
      },
      removeFromCart: function removeFromCart() {
        var _this2 = this;

        var display = this.get('model');
        this.get('cartService').removeFromCart(display).then(function () {
          _this2.sendAction('onRemove', display);
        }, function (err) {
          console.error("Failed to remove from cart: ".concat(err));
        });
      }
    }
  });

  _exports.default = _default;
});