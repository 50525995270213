define("cco-seek/utils/map/display-tooltip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getDisplayTooltipHTML;

  function getDisplayTooltipHTML(number) {
    return "<div class=\"display-tooltip\">#&nbsp;".concat(number || '', "</div>");
  }
});