define("cco-seek/routes/my-displays", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    cartService: Ember.inject.service('cart-service'),
    model: function model() {
      return this.get('cartService').getDisplays();
    }
  });

  _exports.default = _default;
});