define("cco-seek/models/market", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var Market = _emberData.default.Model.extend({
    name: _emberData.default.attr('string')
  });

  Market.reopenClass({
    FIXTURES: [{
      id: 1,
      name: 'Airports'
    }, {
      id: 2,
      name: 'Albuquerque'
    }, {
      id: 3,
      name: 'Atlanta'
    }, {
      id: 4,
      name: 'Boston'
    }, {
      id: 5,
      name: 'Canada'
    }, {
      id: 6,
      name: 'CCOA Corporate'
    }, {
      id: 7,
      name: 'Chicago'
    }, {
      id: 8,
      name: 'Cleveland'
    }, {
      id: 9,
      name: 'Columbus'
    }, {
      id: 10,
      name: 'Dallas/Fort Worth'
    }, {
      id: 11,
      name: 'Daytona Beach/Melbourne'
    }, {
      id: 12,
      name: 'Denver'
    }, {
      id: 13,
      name: 'Des Moines'
    }, {
      id: 14,
      name: 'El Paso'
    }, {
      id: 15,
      name: 'Fort Smith'
    }, {
      id: 16,
      name: 'Houston'
    }, {
      id: 17,
      name: 'Indianapolis'
    }, {
      id: 18,
      name: 'International'
    }, {
      id: 19,
      name: 'Jacksonville'
    }, {
      id: 20,
      name: 'Las Vegas'
    }, {
      id: 21,
      name: 'Los Angeles'
    }, {
      id: 22,
      name: 'Malls'
    }, {
      id: 23,
      name: 'Memphis'
    }, {
      id: 24,
      name: 'Miami/Ft. Lauderdale'
    }, {
      id: 25,
      name: 'Milwaukee'
    }, {
      id: 26,
      name: 'Minneapolis/St. Paul'
    }, {
      id: 27,
      name: 'Montreal'
    }, {
      id: 28,
      name: 'National Sales Group'
    }, {
      id: 29,
      name: 'New York'
    }, {
      id: 30,
      name: 'Ocala/Gainesville'
    }, {
      id: 31,
      name: 'Orlando'
    }, {
      id: 32,
      name: 'Ottawa'
    }, {
      id: 33,
      name: 'Philadelphia'
    }, {
      id: 34,
      name: 'Phoenix'
    }, {
      id: 35,
      name: 'Portland'
    }, {
      id: 36,
      name: 'Region One'
    }, {
      id: 37,
      name: 'Region Two'
    }, {
      id: 38,
      name: 'Reno'
    }, {
      id: 39,
      name: 'Sacramento'
    }, {
      id: 40,
      name: 'Salisbury'
    }, {
      id: 41,
      name: 'Salt Lake City'
    }, {
      id: 42,
      name: 'San Antonio'
    }, {
      id: 43,
      name: 'San Diego'
    }, {
      id: 44,
      name: 'San Francisco Bay Area'
    }, {
      id: 45,
      name: 'Seattle'
    }, {
      id: 46,
      name: 'Specialty Markets'
    }, {
      id: 47,
      name: 'Spectacolor'
    }, {
      id: 48,
      name: 'Tampa Bay'
    }, {
      id: 49,
      name: 'Toronto'
    }, {
      id: 50,
      name: 'Tucson'
    }, {
      id: 51,
      name: 'Vancouver'
    }, {
      id: 52,
      name: 'Washington DC/Baltimore'
    }, {
      id: 53,
      name: 'West Palm Beach'
    }, {
      id: 54,
      name: 'Wichita'
    }]
  });
  var _default = Market;
  _exports.default = _default;
});