define("cco-seek/components/displays-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['displays-list'],
    nearBottom: false,
    didInsertElement: function didInsertElement() {
      var _this = this;

      var displaysEl = this.$().closest('.displays');
      displaysEl.scroll(function () {
        var bottom = Ember.$(displaysEl).scrollTop() + Ember.$(displaysEl).height();
        var scrollBottom = Ember.$(displaysEl).prop('scrollHeight');

        _this.set('isNearBottom', scrollBottom - bottom <= Ember.$(displaysEl).height());
      });
    },
    handleNearBottom: function () {
      if (this.get('isNearBottom')) {
        this.sendAction('onNearBottom');
      }
    }.observes('isNearBottom')
  });

  _exports.default = _default;
});